import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import SubCategories from '../modules/subcategories/SubCategories';

export default function SubcategoriesPage() {
  return (
    <>
      <Helmet>
        <title> Desi In Videsi </title>
      </Helmet>
      <Container>
        <SubCategories />
      </Container>
    </>
  );
}
