import styled from '@emotion/styled';
import { Button, Tooltip, tooltipClasses } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { x } from 'src/assets';

export default function BannerImage({onSelectFiles}) {
    const [file, setFile] = useState([])

    const IconTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(() => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: 'rgb(15 23 42)',
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'rgb(30 41 59)',
        },
    }));

    const deleteHandler = (key) => {
        const list = [...file];
        list.splice(key, 1)
        setFile(list);
    }
    useEffect(()=>{
        file.length>0 && onSelectFiles(file)
    },[file])

    const initialValues = {
        files: ""
    }
    let formData = new FormData();

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                    // await onSubmit(values, accessToken);
                    for (let i = 0; i < file?.length; i++) {
                        formData.append(file[i].name?.split('.pdf')[0], file[i])
                    }
                    
                    for(let i of file.entries()) {
                        console.log(i)
                    }
                }}
            >
                {({
                    errors,
                    values,
                    touched,
                    setFieldValue,
                }) => (
                    <Form>
                        <div className='pb-3'>
                            <label className='text-[0.8rem]'>Upload Banner Images</label>
                            <label className='flex h-9 border rounded-t cursor-pointer' htmlFor="input">
                                <IconTooltip title="Choose File">
                                    <Button variant="text" component="label" size='small' color='inherit' disableRipple="true" class="border-r-2  px-4 text-center flex items-center">
                                        <p className='text-xs text-[#6E6B7B]'>Browse</p>
                                        <Field hidden type="file" id="input" name="file" multiple onChange={(event) => {
                                              setFieldValue("files", Array.from(event.target.files));
                                            const allFiles = file.concat(Array.from(event.target.files));
                                            setFile(allFiles);
                                        }} accept=".jpg" />
                                    </Button>
                                </IconTooltip>
                            </label>
                            {file.length > 0 && <div className='flex z-50 flex-wrap gap-2 border-b p-2 rounded-b border-x'>
                                {file?.map((value, key) => (
                                    <div className='p-1 px-2 flex text-[0.8rem] gap-2 justify-between border rounded-full bg-[#DDE6ED] h-[1.84rem]'>
                                        <p className='font-medium truncate'>{value?.name?.split('.')[0]} &nbsp;</p>
                                        <IconTooltip title={"Delete"}>
                                            <img src={x} className="w-4 cursor-pointer" alt="" onClick={() => deleteHandler(key)} />
                                        </IconTooltip>
                                    </div>
                                ))}
                            </div>}
                        </div>
                        {/* <div className="flex flex-row gap-3 py-3">
                            <button type='submit' className='text-xs bg-orange-400 p-2 px-5 hover:bg-orange-300 font-medium tracking-wide rounded' >Upload</button>
                        </div> */}
                    </Form>
                )}
            </Formik>
        </div>
    )
}