export const HeaderNotShow = ['Code', 'List Id', 'tags', 'category', 'content', 'Country', 'City Name'];
export const noUpdate = [
  'users',
  'headermenu',
  // 'discussion',
  // 'groups',
  'bannerimageupload',
  'advertiseentity',
  'listings',
];
