import React, { useState, useEffect } from 'react';
import { getFirestore } from 'firebase/firestore';
import { Card, Typography, Grid, IconButton, Popover, MenuItem, Stack, TextField, Switch } from '@mui/material';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { app } from '../../firebaseConfig';
import Iconify from '../../components/iconify';
import baseUrl from '../../utils/baseUrl';
import AddCategory from './AddCategory';
import { Button } from '@mui/material';
import swal from 'sweetalert';
import PositionedSnackbar from 'src/components/Snackbar/Snackbar';
import { toast } from 'react-hot-toast';
import MUIDataTable from 'mui-datatables';

const Categories = () => {
  const [subs, setSubs] = useState([]);
  const [open, setOpen] = useState(null);
  const [openedit, setOpenEdit] = useState(null);
  const [sucMsg, setSucMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const db = getFirestore(app);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [checked, setChecked] = useState({});
  const [showOnHomePageChecked, setShowOnHomePageChecked] = useState({});
  const [showOnHomePageSectionChecked, setShowOnHomePageSectionChecked] = useState({});

  const handleActiveSwitchChange = async (event, switchID) => {
    // console.log('handleActiveSwitchChange - status ----', event.target.checked, !checked[switchID], switchID);
    setChecked({ ...checked, [switchID]: !checked[switchID] });

    const patchData = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: JSON.parse(localStorage.getItem('token')),
      },
      body: JSON.stringify({
        status: !!checked[switchID] ? 'deactive' : 'active',
      }),
    };

    try {
      const data = fetch(`${baseUrl}/api/v1/categories/${switchID}`, patchData);
      // const responseData = await data.json();
      if (data) {
        toast.success('Category updated successfully');
      }
    } catch (error) {
      console.log(error);
      toast.error('Error updating custom field');
    }
  };

  const handleShowOnHomePageSwitchChange = async (event, switchID) => {
    // console.log('handleActiveSwitchChange - status ----', event.target.checked, !checked[switchID], switchID);
    setShowOnHomePageChecked({ ...showOnHomePageChecked, [switchID]: !showOnHomePageChecked[switchID] });

    const patchData = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: JSON.parse(localStorage.getItem('token')),
      },
      body: JSON.stringify({
        show_home_page: !!showOnHomePageChecked[switchID] ? 'deactive' : 'active',
      }),
    };

    try {
      const data = fetch(`${baseUrl}/api/v1/categories/${switchID}`, patchData);
      // const responseData = await data.json();
      if (data) {
        toast.success('Category updated successfully');
      }
    } catch (error) {
      console.log(error);
      toast.error('Error updating custom field');
    }
  };
  const handleShowOnHomePageSectionSwitchChange = async (event, switchID) => {
    // console.log('handleActiveSwitchChange - status ----', event.target.checked, !checked[switchID], switchID);
    setShowOnHomePageSectionChecked({
      ...showOnHomePageSectionChecked,
      [switchID]: !showOnHomePageSectionChecked[switchID],
    });

    const patchData = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: JSON.parse(localStorage.getItem('token')),
      },
      body: JSON.stringify({
        show_home_page_section: !!showOnHomePageSectionChecked[switchID] ? 'deactive' : 'active',
      }),
    };

    try {
      const data = fetch(`${baseUrl}/api/v1/categories/${switchID}`, patchData);
      // const responseData = await data.json();
      if (data) {
        toast.success('Category updated successfully');
      }
    } catch (error) {
      console.log(error);
      toast.error('Error updating custom field');
    }
  };

  const fieldColumns = [
    { name: 'id', label: 'ID', options: { filter: false, sort: false, sortThirdClickReset: true, display: true } },
    { name: 'name', label: 'Name', options: { filter: true, sort: false, sortThirdClickReset: true, display: true } },
    { name: 'info', label: 'Info', options: { filter: true, sort: false, sortThirdClickReset: true, display: true } },

    // {
    //   name: 'type',
    //   label: 'Type',
    //   options: {
    //     filter: true,
    //     sort: false,
    //     sortThirdClickReset: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const TYPE = value === 'selectBox' ? 'Select Dropdown ' : value;
    //       return (
    //         <div>
    //           <h5 style={{ textTransform: 'uppercase', color: '#888' }}>{TYPE}</h5>
    //           {value === 'selectBox' || value === 'radio' || value === 'checkboxMulti' ? (
    //             <Button
    //               autoFocus
    //               variant="outlined"
    //               size="small"
    //               onClick={(e) => updateOptions(e, value, tableMeta, updateValue)}
    //             >
    //               Options
    //             </Button>
    //           ) : null}
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: 'category',
    //   label: 'Category / Sub-Category',
    //   options: { filter: false, sort: false, sortThirdClickReset: true },
    // },
    // { name: 'useAsFilter', label: 'Use As Filter', options: { filter: false, sort: false, sortThirdClickReset: true } },
    {
      name: 'active',
      label: 'Show on home page',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Switch
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary" // defaultChecked
            checked={showOnHomePageChecked[tableMeta.rowData[0]]}
            onChange={(event) => handleShowOnHomePageSwitchChange(event, tableMeta.rowData[0])}
          />
        ),
      },
    },

    {
      name: 'active',
      label: 'Show on home page as a section',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Switch
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary" // defaultChecked
            checked={showOnHomePageSectionChecked[tableMeta.rowData[0]]}
            onChange={(event) => handleShowOnHomePageSectionSwitchChange(event, tableMeta.rowData[0])}
          />
        ),
      },
    },

    {
      name: 'active',
      label: 'Active',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Switch
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary" // defaultChecked
            checked={checked[tableMeta.rowData[0]]}
            onChange={(event) => handleActiveSwitchChange(event, tableMeta.rowData[0])}
          />
        ),
      },
    },
    {
      name: 'actions',
      label: 'Edit',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // const arr = allFields.filter((item) => item.id === tableMeta.rowData[0])[0];
          // console.log('selected record:', arr);
          // console.log('getiing arr:', arr.options);
          return (
            <MenuItem onClick={() => handleOpenEdit(tableMeta.tableData[tableMeta.rowIndex])}>
              <Iconify icon={'eva:edit-fill'} />
            </MenuItem>
          );
        },
      },
    },
  ];

  const getMuiThemeLight = () =>
    createTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: '#AAF',
          },
        },
      },
      components: {
        MuiPopover: {
          styleOverrides: {
            paper: {
              borderStyle: 'solid',
              borderWidth: 2,
              borderRadius: 10,
              minWidth: '30%',
            },
          },
        },
      },
    });
  const handleOpenMenu = (event, item) => {
    setOpen(event.currentTarget);
    setSelectedCategory(item);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const [value, setValue] = useState({ name: '' });
  const [message, setMessage] = useState({ error: false, msg: '' });

  const handleChange = (event) => {
    const name = event.target.name;
    const val = event.target.value;
    setValue((values) => ({ ...values, [name]: val }));
  };

  const categoryDelete = async (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to Delete?',
      icon: 'warning',
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const requestOptions = {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: String(JSON.parse(localStorage.getItem('token'))),
          },
        };
        try {
          const data = await fetch(`${baseUrl}/api/v1/categories/${id}`, requestOptions);
          const resolvedData = await data.json();
          console.log(resolvedData);
          if (resolvedData) {
            setMessage({ error: false, msg: 'Deleted Successfully!' });
            fetchSubs();
            toast.success('Deleted Successfully!');
            setOpen(false);
            handleCloseEdit();
          } else {
            setMessage({ error: true, msg: 'error occured' });
          }
        } catch (error) {
          console.log(error);
          setMessage({ error: true, msg: 'error occured' });
        }
      }
    });
  };

  const handleSubmit = async (e, id) => {
    // setValue('');
    e.preventDefault();

    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(JSON.parse(localStorage.getItem('token'))),
      },
      body: JSON.stringify({
        name: value.name,
        icon: 'dummy',
        // active: true,
      }),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/categories/${id}`, requestOptions);
      const resolvedData = await data.json();
      console.log(resolvedData);
      if (resolvedData) {
        // setMessage({ error: false, msg: 'Category Updated Successfully!' });
        toast.success('Category Updated Successfully!');
        fetchSubs();
        setOpen(false);
        handleCloseEdit();
      } else {
        setMessage({ error: true, msg: 'error occured' });
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    } finally {
      console.log();
    }
    console.log(selectedCategory);
  };

  const fetchSubs = async () => {
    const checkedObj = {};
    const showOnHomePageObj = {};
    const showOnHomePageSectionObj = {};

    try {
      const data = await fetch(`${baseUrl}/api/v1/categories`);
      const arr = await data.json();

      arr?.data?.allCategories.map((i) => {
        checkedObj[i?.id] = i?.status === 'active' ? true : false;
        showOnHomePageObj[i?.id] = i?.show_home_page === 'active' ? true : false;
        showOnHomePageSectionObj[i?.id] = i?.show_home_page_section === 'active' ? true : false;
      });
      setChecked(checkedObj);
      setShowOnHomePageChecked(showOnHomePageObj);
      setShowOnHomePageSectionChecked(showOnHomePageSectionObj);

      setSubs(arr?.data?.allCategories);
    } catch (error) {
      console.log(error);
      setSubs([]);
    }
  };
  useEffect(() => {
    fetchSubs();
  }, []);

  const snackBarHandler = (data) => {
    console.log(data);
    setOpen(data.open);
    setErrMsg(data.errMsg);
    setSucMsg(data.sucMsg);
    // setReload(data.reload);
    data.reload && fetchSubs();
  };

  const handleOpenEdit = (item) => {
    setSelectedCategory(item);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setValue({ ...value, name: selectedCategory?.name });
  }, [selectedCategory]);

  return (
    <>
      <PositionedSnackbar open={openSnackBar} message_err={errMsg} message_suc={sucMsg} handleClose={handleClose} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Categories
        </Typography>
        {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add New Subject
          </Button> */}
        <AddCategory fetchSubs={fetchSubs} onSnackBar={snackBarHandler} />
      </Stack>

      <Card>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={getMuiThemeLight()}>
            <ThemeProvider theme={getMuiThemeLight()}>
              <MUIDataTable
                title={'All Fields'}
                data={subs}
                columns={fieldColumns}
                options={{
                  responsive: 'standard',
                  print: false,
                  search: true,
                  viewColumns: false,
                  download: false,
                  pagination: true,
                  selectableRows: 'none',
                  filter: true,
                  filterType: 'dropdown',
                  rowsPerPage: 10,
                  rowsPerPageOptions: [20],
                }}
              />
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </Card>

      {/* <Grid container spacing={3}>
        {subs?.map((item, index) => (
          <Grid key={index} item xs={12} sm={6} md={3}>
            <Card>
              <Stack direction={'row'} justifyContent={'space-between'} spacing={2} sx={{ p: 3 }}>
                <Typography variant="subtitle2" noWrap>
                  {item.name}
                </Typography>
                <IconButton size="small" color="inherit" onClick={(event) => handleOpenMenu(event, item)}>
                  <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid> */}

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 100,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* <MenuItem onClick={handleOpenEdit}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem> */}
        {/* <MenuItem onClick={() => categoryDelete(selectedCategory?.id)}>
          <Iconify icon={'eva:close-fill'} sx={{ mr: 2 }} />
          Remove
        </MenuItem> */}
      </Popover>
      <Dialog open={openedit} onClose={handleCloseEdit} maxWidth={'xs'} fullWidth>
        <DialogTitle>Edit Category</DialogTitle>
        {selectedCategory && (
          <DialogContent sx={{ padding: 2 }}>
            <TextField
              variant="filled"
              color="info"
              fullWidth
              label="Name"
              name="name"
              value={value?.name || ''}
              onChange={handleChange}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCloseEdit} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={(e) => handleSubmit(e, selectedCategory?.id)}
            variant="outlined"
            className="bg-blue-800 text-white hover:bg-blue-700"
          >
            Edit Category
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Categories;
