// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { AuthProvider } from './utils/services/auth/authService';
import { Toaster } from 'react-hot-toast';

export default function App() {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <AuthProvider>
      <Toaster
        // position="top-right"
        position="bottom-left"
        reverseOrder={true}
        gutter={8}
        containerClassName="font-[Poppins] p-1"
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            // backgroundColor: "rgba(17, 25, 40, 0.75)",
            // color: "#fff",
            background: '#F1EBEB6B',
            fontSize: 13,
            border: '1px solid rgba(255, 255, 255, 0.125)',
            padding: '10px',
            backdropFilter: 'blur(2.34597px)',
            fontWeight: 600,
            marginBottom: '10px',
          },
        }}
      />
        <Router />
      </AuthProvider>
    </ThemeProvider>
  );
}
