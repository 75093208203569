import React, {useEffect, useState } from "react";
import axios from "axios";

const useCountry = () => {
  const [countries, setCountries] = useState([]);
  const [accessToken, setAccessToken] = useState(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJfZW1haWwiOiJzYXJvanRoYWt1cjI0N0BnbWFpbC5jb20iLCJhcGlfdG9rZW4iOiI2bzY5OHY1ejFGM1h1YjFTZEk4eGcxM2NpcmFGalQ4S1RmOTZESmVqR3hKM3p0UDlHT3ZEbkQ2bzFGakxINElOa01rIn0sImV4cCI6MTY3ODE3MzMyMn0.XsRChPH6pHONKCt2klcM6424YW2NGTEOjKSlBP0iKHU"
  );

  useEffect(() => {
    if (!!accessToken) getAllCountries(accessToken);
     return () => {
       // Cancel any pending API requests
       // if the component unmounts
       axios.CancelToken.source().cancel();
     };
  }, [accessToken]);

  const getAllCountries = async (accessToken) => {
    try {
      const data = await axios({
        method: "get",
        url: "https://www.universal-tutorial.com/api/countries",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json",
        },
      });
      const countryOptions = data?.data.map((i) => ({
        id: i.country_name,
        name: i.country_name,
      }));
      setCountries(countryOptions);
    } catch (error) {
      getNewAccessToken();
      // alert("error", error);
    }
  };
  const getNewAccessToken = async () => {
    try {
      const data = await axios({
        method: "GET",
        url: "https://www.universal-tutorial.com/api/getaccesstoken",
        headers: {
          Accept: "application/json",
          "api-token":
            "6o698v5z1F3Xub1SdI8xg13ciraFjT8KTf96DJejGxJ3ztP9GOvDnD6o1FjLH4INkMk",
          "user-email": "sarojthakur247@gmail.com",
        },
      });

      // console.log("new Token data", data?.data?.auth_token);

      setAccessToken(data?.data?.auth_token);
      // getAllCountries();
    } catch (error) {
      console.log(error);
    }
  };

  return [countries];
};

export default useCountry;
