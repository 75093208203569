import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { Stack, Container, Typography, Box, CircularProgress } from '@mui/material';
import { getFirestore } from 'firebase/firestore';
import DataGrid from '../components/Datagrid/Datagrid';
import { app } from '../firebaseConfig';
import baseUrl from '../utils/baseUrl';
import AddAdvertise from '../modules/advertise/AddAdvertise';
import AddHeader from 'src/modules/Header/AddHeader';
import Component from 'src/modules/Header/UpdateHeader';
import PositionedSnackbar from 'src/components/Snackbar/Snackbar';

export default function HeaderPage() {
  const [open, setOpen] = useState(false);
  const [sucMsg, setSucMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [allFields, setAllFields] = useState([]);
  const [checked, setChecked] = useState({});
  const [allAddAdvertise, setAllAddAdvertise] = useState([]);
  const [allFilterArr, setAllFilterArr] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    search_item: '',
    type_selection: 'page_name',
  });
  const [placeholderTxt, setPlaceholderTxt] = useState('Enter page name');
  const db = getFirestore(app);

  useEffect(() => {
    fetchVideo();
  }, [reload]);

  const fetchVideo = async () => {
    const arr = [
      { name: 'Search by id', value: 'id' },
      { name: 'Search by Page Name', value: 'page_name' },
      { name: 'Search by Location', value: 'location_name' },
    ];
    setAllFilterArr(arr);
    const checkedObj = {};
    try {
      setLoading(true);
      const data = !inputs.search_item
        ? await fetch(`${baseUrl}/api/v1/header_menu`)
        : await fetch(`${baseUrl}/api/v1/advertisement/location?${inputs.type_selection}=${inputs.search_item}`);
      //   const data = route
      const arr = await data.json();
      const modifiedData = arr?.data?.menuList?.map((i) => {
        return {
          ...i,
        };
      });
      //   console.log(arr)
      const modifiedDataSort = modifiedData.sort((a, b) => a.id - b.id);
      setChecked(checkedObj);
      setAllFields([...modifiedDataSort]);
      setAllAddAdvertise([...modifiedDataSort]);
    } catch (error) {
      // console.log(error);
      await setAllFields([]);
    } finally {
      setLoading(false);
    }

    // // console.log(checked);
  };

  // console.log(allFields);

  // const Component = ()=>(<UpdatedAdvertise/>)

  const onClickEvent = () => {};
  const snackBarHandler = (data) => {
    console.log(data);
    setOpen(data.open);
    setErrMsg(data.errMsg);
    setSucMsg(data.sucMsg);
    // setReload(data.reload);
    data.reload && fetchVideo();
  };
  const reloadHandler = (data) => {
    setReload(data);
  };

  const FilteredData = allFields?.map((field, key) => {
    return {
      Code: field.id,
      Id: field.id,
      'Header Name': field.name,
      // 'Base Path': field.base_href,
      Status: field.status,
    };
  });

  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Helmet>
        <title> Header Menu </title>
      </Helmet>
      {/* <PositionedSnackbar
          open={open}
          message_err={errMsg}
          message_suc={sucMsg}
          handleClose={handleClose}
        /> */}

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Header Menu
          </Typography>
          <AddHeader allFields={allFields} fetchVideo={fetchVideo} onSnackBar={snackBarHandler} />
        </Stack>

        {FilteredData?.length > 0 ? (
          <DataGrid
            data={FilteredData}
            onClickEvent={onClickEvent}
            checkBox
            pagingTool
            hideTool
            isFilter
            empGrid
            wrap
            name="/api/v1/header_menu/"
            // componentName="editExpenceForm"
            // viewName="viewExpenseForm"
            openSnackBar={snackBarHandler}
            // fetchVideo={fetchVideo}
            renderComponent={Component}
            // reload = {reloadHandler}
          />
        ) : loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <CircularProgress /> &nbsp;Loading....
          </Box>
        ) : (
          <h1 className="text-base text-center font-semibold">Data Not Found!!</h1>
        )}
      </Container>
    </div>
  );
}
