import React, { useState } from 'react';
import { Button, Chip, Grid, Stack, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import baseUrl from '../../utils/baseUrl';
import { toast } from 'react-hot-toast';

const AddCategory = ({ fetchSubs, onSnackBar }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({ name: '' });
  const [message, setMessage] = useState({ error: false, msg: '' });
  const [optionsTxt, setOptionsTxt] = useState('');
  const [options, setOptions] = useState([]);

  const handleAddOption = () => {
    if (optionsTxt) {
      console.log('event------------->', optionsTxt, options);
      setOptions([...options, optionsTxt]);
      setOptionsTxt('');
    }
  };
  const handleAddOptionTxt = (event) => {
    console.log('event------------->', event.target.value);
    setOptionsTxt(event.target.value);
  };
  const handleDeleteOptions = (event) => {
    console.log('event------------->', event);
    const optionsList = options.filter((name) => name !== event);
    setOptions(optionsList);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const val = event.target.value;
    setValue((values) => ({ ...values, [name]: val }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(JSON.parse(localStorage.getItem('token'))),
      },
      body: JSON.stringify({
        name: value.name,
        icon: 'icon',
        info: 'some info(optional)',
        keywords: options,
      }),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/categories`, requestOptions);
      const resolvedData = await data.json();
      console.log(resolvedData);
      if (resolvedData?.errors?.length === 0) {
        // setMessage({ error: false, msg: 'Added Successfully!' });
        toast.success('Category added successfully!');
        fetchSubs();
        setValue({ name: '' });
        setOptions([]);
        setOpen(false);
      } else {
        setMessage({ error: true, msg: 'error occured' });
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValue({ name: '' });
    setOptions([]);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add New Category
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                variant="filled"
                color="info"
                fullWidth
                label="Name"
                name="name"
                value={value.name || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                // autoFocus
                // margin="dense"
                id="optionsTxt"
                label="Keywords"
                type="text"
                variant="filled"
                color="info"
                fullWidth
                name="optionsTxt"
                value={optionsTxt}
                onChange={handleAddOptionTxt}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <Button onClick={handleAddOption} variant="outlined">
                Add Keywords
              </Button>
            </Grid>

            <Grid item xs={12} md={12}>
              <Stack direction="row" spacing={2} flexWrap={'wrap'}>
                {options.map((item, index) => (
                  <Chip
                    style={{
                      marginTop: 10,
                    }}
                    label={item}
                    variant="filled"
                    color="info"
                    fullWidth
                    onDelete={() => handleDeleteOptions(item)}
                    key={item}
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="outlined">
            Add Category
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddCategory;
