import { useField, FormikErrors } from "formik";
// const props = {
//     name: string,
//     value: string,
//     rows: number,
//     placeholder: string,
//     asterisk: booleanstring,
//     error: FormikErrors | undefined,
//     label: string,
// }
const TextArea = ({ ...props }) => {
    //@ts-ignore
    const [field, meta] = useField(props);
    return (
        <div className="flex flex-col">
            <div className="flex">
                <label className={`text-xs tracking-wide ${props?.error && 'text-[#FF0000]'}`}>{props?.label}</label>
                {props?.asterisk && <i className="fa fa-solid fa-asterisk " style={{ color: 'red', fontSize: 6, paddingLeft: 1 }} ></i>}
            </div>
            {(props?.value) ? <textarea className="rounded border text-sm bg-[#f1efef] text-gray-700 py-1 px-2 cursor-not-allowed" {...field} {...props} disabled={true} value={props?.value}/>
                : <>
                    <textarea className={`rounded border focus:border-indigo-800 text-sm outline-none text-gray-700 py-1 px-2 ${props?.error && 'border-[#FF0000]'}`} {...field} {...props}/>
                    <div className="text-[#FF0000] text-xs">
                        {/* @ts-ignore */}
                        {props?.error}
                    </div>
                </>
            }
        </div>
    );
};

export default TextArea