/* eslint-disable arrow-body-style */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
    Stack,
    Container,
    Typography,
    Box,
    CircularProgress, 
} from '@mui/material';
import DataGrid from '../components/Datagrid/Datagrid';
import baseUrl from '../utils/baseUrl';
import PositionedSnackbar from 'src/components/Snackbar/Snackbar';
import AddGroups from 'src/modules/Groups/AddGroups';
import Component from 'src/modules/Groups/UpdateGroups';

export default function Groups() {
    const [open, setOpen] = useState(false);
    const [sucMsg, setSucMsg] = useState("")
    const [errMsg, setErrMsg] = useState("")
    const [reload, setReload] = useState(false)
    const [allFields, setAllFields] = useState([]);
    const [checked, setChecked] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchVideo();
    }, [reload]);


    const fetchVideo = async () => {
        try {
            setLoading(true);
            const stringfyData = await fetch(`${baseUrl}/api/v1/groups/room`);
            const arr = await stringfyData.json();
            // const modifiedArr = arr?.data?.users.map((i) => ({
            //     ...i,
            //     role: i.Role.role,
            // }));
            // console.log(modifiedArr);
            setAllFields(arr?.data?.totalChatRooms);
            console.log(arr.totalChatRooms);
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    };


    const FilteredData = allFields?.map((field, key) => {
        return {
            'Serial No': key + 1,
            Code: field.id,
            'Room Name': field.room_name,
            'Room Type': field.room_type,
            'Location': field.location,
            'Created By': field.User.user_name,
            'Date Created': field.createdAt?.substring(0, 10),
            'Status': field?.status,
            category: field.categoryId,
            tags: field.tags_on_chat_room,
        }
    })
    
    const onClickEvent = () => {

    }
    const snackBarHandler = (data) => {
        console.log(data)
        setOpen(data.open);
        setErrMsg(data.errMsg);
        setSucMsg(data.sucMsg);
        // setReload(data.reload);
        data.reload && fetchVideo()
    }
    const handleClose = () => {
        setOpen(!open);
    }

    return (
        <>
            <Helmet>
                <title> Groups </title>
            </Helmet>

            <Container>
                {/* <PositionedSnackbar
                    open={open}
                    message_err={errMsg}
                    message_suc={sucMsg}
                    handleClose={handleClose}
                /> */}
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Groups
                    </Typography>
                    <AddGroups onSnackBar={snackBarHandler}/>
                </Stack>
                {allFields?.length > 0 ? <DataGrid
                    data={FilteredData}
                    onClickEvent={onClickEvent}
                    checkBox
                    pagingTool
                    hideTool
                    isFilter
                    empGrid
                    wrap
                    name="/api/v1/groups/room/"
                    renderComponent={Component}
                    openSnackBar={snackBarHandler}
                /> : loading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}><CircularProgress /> &nbsp;Loading....</Box>
                    : <h1 className="text-base text-center font-semibold">Data Not Found!!</h1>}
            </Container>
        </>
    );
}
