import React,{useEffect, useState} from "react";
import { styled } from "@mui/material/styles";
// import { useAppSelector } from "../redux/store/store";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { Edit, update } from "src/assets";
// import Component from "src/modules/advertise/UpdateAdvertise";
import Iconify from '../iconify';
import { Dialog } from "@mui/material";
// import { Edit } from "../../assets/index";
// import { login,Logout } from "../redux/slices/userSlice";



const Update = ({args, Component, onSnackBar}) => {
    const [ob,setOb] = useState([])
    const [open,setOpen] = useState(false)
    const [data,setData] = useState({})
    const navigate = useNavigate()
    const BgblackTooltips = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.black,
          color: "#fff",
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      }));
      function withoutProperty(obj, property) {  
          const { [property]: unused, ...rest } = obj
      
        return rest
      }
      const handleClose = ()=>{
        setOpen(!open)
        // onSnackBar && onSnackBar(true)
      }
    const updateHandler = () => {
        setOpen(!open)
        setData(withoutProperty(args,'column'))
      }
    const onCancel = (cancel) => {
      cancel && handleClose()
    }
    // console.log(args)
    return(
      <div>
        <BgblackTooltips title="Update"  arrow>
          <i className="fa-solid fa-pen-to-square text-[#203f74] text-base" onClick={updateHandler}></i>
        </BgblackTooltips>
        <Dialog open={open} onClose={handleClose} >
          {(Component) && Component(args,onCancel,onSnackBar)}
        </Dialog>
      </div>
    )
  }
export default Update