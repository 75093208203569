import React, { useState, useEffect, useRef, useCallback } from 'react';
import Button from '@mui/material/Button';
import Select from 'react-select';
import { Box, Grid, DialogTitle, DialogActions, CircularProgress } from '@mui/material';
// From firebase
import baseUrl from '../../utils/baseUrl';
import { Form, Formik } from 'formik';
import TextFieldBox from '../custom_fields/TextFieldBox';
import DropDownBox from '../custom_fields/DropDownBox';
import useCountry from 'src/utils/useCountry';
import useCategories from 'src/utils/useCategories';
import useTagList from 'src/utils/useTagList';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import TextArea from '../custom_fields/TextArea';
import { toast } from 'react-hot-toast';
import { useAuth } from 'src/utils/services/auth/authService';

const UpdateDiscussion = (props, onCancel, onSnackBar) => {
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [coun, setCoun] = useState([]);
  const [statusAllArr, setStatus] = useState([]);
  const [location, setLocation] = useState([]);
  const [selectedTag, setseletedTag] = useState(
    props?.tags?.map((item) => ({ id: item.tag_id, tag: item.tags.tag })) || []
  );
  const tagInput = useRef(null);
  const [tagList, setTagList, loadingState] = useTagList(searchKey);
  const [inputs, setInputs] = useState({});
  const [message, setMessage] = useState({ error: false, msg: '' });
  const [options, setOptions] = useState([]);
  const [showOptionField, setShowOptionField] = useState(false);
  const [countries] = useCountry(null);
  const [listCategories] = useCategories();
  const [selectedState, setSelectedState] = useState({
    label: '',
    value: '',
  });
  const auth = useAuth();

  const fetchCoun = async () => {
    const arr = [
      { value: 1, name: 'Home' },
      { value: 2, name: 'Listing' },
    ];
    const arrStatus = [
      { id: 1, name: 'active' },
      { id: 2, name: 'pending' },
      { id: 3, name: 'deactive' },
    ];

    setCoun(arr);
    setStatus(arrStatus);
  };

  const handleAddTagInDb = async () => {
    try {
      setLoading(true);
      const url = `${baseUrl}/api/v1/disscussion-tags`;
      const payload = { tag: tagInput.current.value };

      const data = await fetch(url, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const resData = await data.json();
      if (resData.data) {
        setseletedTag((prev) => [...prev, resData.data]);
        tagInput.current.value = ''; //clear the form
      } else {
        // toast.error("Server error !");
      }
    } catch (err) {
      //   toast.error("Something went wrong", err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTagSelect = (tag) => {
    for (let i = 0; i < selectedTag.length; i++) {
      if (selectedTag[i].id == tag.id) {
        // toast.error("Tag selected already");
        return false;
      }
    }
    setseletedTag((prev) => [...prev, tag]);
    setTagList([]);
    tagInput.current.value = ''; //clear the form
  };

  const handleRemoveTag = (id) => {
    setseletedTag((previ) => previ.filter((item) => item.id !== id));
  };

  const changeHandler = async (event) => {
    await setSearchKey(event.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 800), []);
  // after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  /** *********************[useEffect]*********************** */
  useEffect(() => {
    fetchCoun();
    fetchVideo();
    // fetchTrans();
  }, []);
  useEffect(() => {
    if (['selectBox', 'checkboxMulti', 'radio'].includes(inputs.type_of_field)) {
      setOptions([]);
      setShowOptionField(true);
    } else {
      setOptions([]);
      setShowOptionField(false);
    }
  }, [inputs]);

  useEffect(() => {
    auth.getCityList(selectedState.value);
  }, [selectedState]);

  const handleClose = () => {
    onCancel(true);
  };

  const fetchVideo = async () => {
    // const arr = [
    //   { name: 'Search by id', value: 'id' },
    //   { name: 'Search by Page Name', value: 'page_name' },
    //   { name: 'Search by Location', value: 'location_name' },
    // ];
    // setAllFilterArr(arr);
    const checkedObj = {};
    try {
      // setLoading(true);
      const data = await fetch(`${baseUrl}/api/v1/advertisement/location`);
      const arr = await data.json();
      const modifiedData = arr?.data?.map((i) => {
        return {
          ...i,
        };
      });
      const modifiedDataSort = modifiedData.sort((a, b) => a.id - b.id);
      // await setChecked(checkedObj);
      setLocation(modifiedDataSort.map((i) => ({ name: i.location_name, id: i.id })));
      // await setAllAddAdvertise([...modifiedDataSort]);
    } catch (error) {
      // console.log(error);
      // await setAllFields([]);
    }
    // finally {
    //   setLoading(false);
    // }

    console.log(location);
  };
  const handleSubmit = async (values) => {
    // e.preventDefault();
    //token
    // if (!token) {
    //   toast.error("Please log in before create an discussion");
    //   return;
    // }
    if (!values['post_title'] || !values['content']) {
      //   toast.error("Please, fillup all required fields", "error");
      onSnackBar({
        open: true,
        sucMsg: '',
        errMsg: 'Please, fillup all required fields',
      });
      return;
    }
    try {
      setLoading(true);
      const url = `${baseUrl}/api/v1/disscussion-post/${props.Code}`;
      values.tags = selectedTag.map((tag) => (tag = tag.id));
      values.meta_title = values['post_title'].split(' ').join('+');
      const data = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        },
        body: JSON.stringify(values),
      });
      const resData = await data.json();

      if (resData.data) {
        // resData.data && toast.success("discussion Created sucessfully");
        resData.data &&
          onSnackBar({
            open: true,
            errMsg: '',
            sucMsg: 'Discussion updated sucessfully',
            reload: true,
          });
        toast.success('Discussion updated sucessfully');
        handleClose();
        // formik.resetForm();
        setseletedTag([]);
      } else {
        toast.error('Please, fillup all required field');
      }
    } catch (error) {
      if (error.message == 'unAuthorized') {
        // toast.error("Please log in before create an discussion");
        onSnackBar({
          open: true,
          sucMsg: '',
          errMsg: 'Please log in before create an discussion',
        });
      } else {
        // toast.error("Please, fillup all required field", error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  console.log(props);

  return (
    <>
      <DialogTitle>Update Discussion</DialogTitle>
      <div>
        {/* <DialogContentText>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </DialogContentText> */}
        <Box>
          <Formik
            initialValues={{
              post_title: props?.Name,
              location: props?.Location,
              meta_title: '',
              tags: selectedTag.map((tag) => (tag = tag.id)), //only send ids
              content: props?.content,
              categoryId: props.category,
            }}
            // validationSchema={validationSchema}
            onSubmit={async (values) => {
              // console.log(values)
              await handleSubmit(values);
            }}
          >
            {({
              //@ts-ignore
              errors,
              values,
              setFieldValue,
              //@ts-ignore
              touched,
            }) => (
              <Form>
                <Box p={3}>
                  <Box width="100%" component="form" method="post" autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <TextFieldBox
                          label="Discussion Title"
                          name="post_title"
                          //   options = {coun}
                          type="text"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <DropDownBox label="Location (Only Country)" name="location" options={countries} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={colourOptions[0]}
                            placeholder="Select State"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                background: '#fff',
                                borderTop: 'none',
                                borderRight: 'none',
                                borderLeft: 'none',
                                borderColor: '#cccccc',
                                borderRadius: 'none',
                                minHeight: '38px',
                                height: '38px',
                                boxShadow: state.isFocused ? null : null,
                              }),
                            }}
                            labelledBy="Select"
                            value={selectedState.value === '' ? null : selectedState}
                            onChange={(e) => setSelectedState(e)}
                            options={auth.stateList}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={colourOptions[0]}
                            placeholder="Select City"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                background: '#fff',
                                // borderColor: values[`location`] ? 'red' : '#cccccc',
                                borderTop: 'none',
                                borderRight: 'none',
                                borderLeft: 'none',
                                borderColor: '#cccccc',
                                borderRadius: 'none',
                                minHeight: '38px',
                                height: '38px',
                                boxShadow: state.isFocused ? null : null,
                              }),
                            }}
                            isSearchable={true}
                            onChange={(e) =>
                              setFieldValue('location', e.value + ',' + selectedState.value + ',' + 'USA')
                            }
                            labelledBy="Select"
                            options={auth.cityList}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="flex flex-col">
                          <label>Discussion Tags</label>
                          <div className="">
                            {selectedTag?.length > 0 && (
                              <div className="">
                                {selectedTag.map((tag) => (
                                  <span key={tag.id} className="chip badge badge-pill badge-info border rounded px-1">
                                    {tag.tag}
                                    <span
                                      style={{
                                        padding: '0 0px 0 10px',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => handleRemoveTag(tag.id)}
                                    >
                                      <FontAwesomeIcon icon={faXmark} />
                                    </span>
                                  </span>
                                ))}
                              </div>
                            )}
                            <input
                              type="text"
                              className="w-full border outline-none p-[0.65rem] rounded focus:bg-gray-50"
                              placeholder="Exp: Keyword1"
                              name="listingTitle"
                              autoComplete="off"
                              // onChange={(value) => {
                              //   handleChangeFormValue("tags", value);
                              // }}
                              // value={formik.values[`tags`]}
                              ref={tagInput}
                              onChange={debouncedChangeHandler}
                            />
                            {loadingState && (
                              // <Spinner
                              //     color="success"
                              //     className="product-spinner"
                              //     animation="border"
                              //     size="sm"
                              // />
                              <Box sx={{ display: 'flex' }}>
                                <CircularProgress size="small" />
                              </Box>
                            )}
                            {tagList?.length > 0 && (
                              <ul className="border pl-2 rounded">
                                {tagList.map((tag) => (
                                  <li key={tag.id} className="cursor-pointer">
                                    <span onClick={() => handleTagSelect(tag)}>{tag.tag}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                            {tagList?.length == 0 && tagInput.current?.value && !loadingState && (
                              <Button onClick={handleAddTagInDb} className="btn btn-light mt-1">
                                Click to Add
                              </Button>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextArea
                          label="Description"
                          // type="text"
                          name="content"
                          rows={4}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <DialogActions sx={{ padding: 3 }}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    // onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Box>
      </div>
    </>
  );
};

export default UpdateDiscussion;
