import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Active } from 'src/assets';
import baseUrl from '../../utils/baseUrl';
import { Box, CircularProgress, Dialog, DialogContent, Stack, Switch, Typography } from '@mui/material';
import { toast } from 'react-hot-toast';
// import { Active } from "../../assets/index";
// import { generateRefreshToken } from "./generateaccessToken";

const ActiveData = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(props.args.Status === 'active' ? true : false);
  const verifyApi = `${baseUrl}${props?.name}${props?.args?.Code}`;
  const rejectApi = `${baseUrl}${props?.name}${props?.args.Code}`;

  const deleteHandler = async () => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    // myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append('Authorization', JSON.parse(localStorage.getItem('token')));
    try {
      await fetch(rejectApi, {
        method: 'PATCH',
        body: JSON.stringify({
          // bill_id: props.args["Bill Id"],
          status: 'deactive',
        }),
        headers: myHeaders,
      })
        .then(async (response) => {
          return Promise.all([response.status, response.json()]);
        })
        .then(([status, data]) => {
          console.log(data);
          setOpen(false);

          if (status === 200 || status === 201) {
            props.onDeActive({
              open: true,
              sucMsg: data?.data?.msg || 'Data deactivated successfully!',
              errMsg: '',
            });
            toast.success(data?.data?.msg || 'Data deactivated successfully!');
            setTimeout(() => {
              props.onDeActive({ reload: true });
            }, 1000);
          } else {
            props.onDeActive({
              open: true,
              sucMsg: '',
              errMsg: data?.data?.msg,
              reload: true,
            });
            toast.error(data?.data?.msg);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  const activeHandler = async () => {
    // console.log(accessToken)
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', String(JSON.parse(localStorage.getItem('token'))));
    try {
      await fetch(verifyApi, {
        method: 'PATCH',
        body: JSON.stringify({
          // bill_id: props.args["Bill Id"],
          status: 'active',
        }),
        headers: myHeaders,
      })
        .then(async (response) => {
          return Promise?.all([response.status, response.json()]);
        })
        .then(([status, data]) => {
          setOpen(false);
          // console.log(data)
          if (status === 200 || status === 201) {
            //  For opening SnackBar
            props.onActive({
              open: true,
              sucMsg: data?.data?.msg || 'Data activated successfully!',
              errMsg: '',
            });
            toast.success(data?.data?.msg || 'Data activated successfully!');
            setTimeout(() => {
              props.onActive({ reload: true });
            }, 1000);
          } else {
            //  setErrMsg(data.msg)
            props.onActive({
              open: true,
              sucMsg: '',
              errMsg: data?.data?.msg,
              reload: true,
            });
            toast.error(data?.data?.msg);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };
  // console.log(accessToken)
  // useEffect(()=>{
  //   if(token) activeHandler()
  // },[token])

  const BgblackTooltips = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: '#fff',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const handleChange = (event) => {
    setOpen(true);
    setChecked(event.target.checked);
    event.target.checked ? activeHandler() : deleteHandler();
  };

  // useEffect(() => {
  //   if (props.args.Status === 'deactive' || props.args.Status === 'pending') {
  //     setChecked(false);
  //   } else {
  //     setChecked(true);
  //   }
  // }, []);
  return (
    <div className="flex">
      <Dialog open={open} maxWidth="sm" fullWidth={true}>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <CircularProgress /> &nbsp;Loading....
          </Box>
        </DialogContent>
      </Dialog>
      {/* <BgblackTooltips title={"Activate"} arrow>
        <img
          src={Active}
          width="17px"
          height="17px"
          style={{cursor: "pointer"}}
          alt=""
          onClick={() => {setOpen(true); activeHandler()}}
        />
      </BgblackTooltips> */}
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
      </Stack>
    </div>
  );
};
export default ActiveData;
