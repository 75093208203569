// import { options } from 'numeral';
import { MenuItem, Select, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React from 'react';

function DropDownBox(props) {
    const [field,meta] = useField(props)
    const {values,setFieldValue} = useFormikContext()
    return (
        // <div>
            <TextField
                select
                // labelId="demo-simple-select-label"
                // id="demo-simple-select"
                name={props.name}
                value={props.value}
                variant="standard"
                disabled={props?.disabled}
                // size="medium"
                fullWidth
                label={props.label}
                onChange={(e)=> setFieldValue(props.name,e.target.value)}
                {...field}
            >
                {props.options.map((item) => (
                    <MenuItem value={item.id ? item.id : item.name}>
                    {item.name}
                </MenuItem>
                ))}
            </TextField>
        // </div>
    );
}

export default DropDownBox;