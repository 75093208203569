import { useEffect, useState } from "react";
// import { ApiBaseUrl, ApiRoutes } from "../const";
import axios from "axios";
import baseUrl from "./baseUrl";

const useTagList = (tag) => {
    const [tagList, setTagList] = useState([]);
    const [loadingState, setLoadingState] = useState(false);

    useEffect( () => {
        const GetSearchTagList = async (tag) => {
          if (!tag?.length) {
            setTagList([]);
            return;
          }
        setLoadingState(true);
          try {
            const response = await axios.get(
              `${baseUrl}/api/v1/disscussion-tags/?tag=${tag}`
            );
            let {data} = response?.data || [];
              if (data.length === 0) {
                // setSearchKey(tag);
              }
              setTagList(data);
          } catch (error) {
            console.error(error);
          } finally {
            setLoadingState(false);
          }
        };
        GetSearchTagList(tag);
        return () => {
          // Cancel any pending API requests
          // if the component unmounts
          axios.CancelToken.source().cancel();
        };
    }, [tag]);

    return [tagList,setTagList, loadingState];
}

export default useTagList