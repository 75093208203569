/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import axios from 'axios';
import { useAuth } from "./authService";
import baseUrl from '../../baseUrl';
import { basicSwalAlert } from '../../../utils/services/swal.service';

export const RequireAuth = ({ children }) => {
  const auth = useAuth();

  if (!auth?.user) {
    const token = localStorage.getItem('token');
    if (token) {
      getUserInfo();
      return
    }
    localStorage.removeItem("token");
    return <Navigate to="/login" />;
  }

  // async function getUserInfo() {
  //   try {
  //     let userData = await axios.get(`${baseUrl}/api/v1/auth/account`, {
  //       headers: {
  //         Authorization: `Bearer ${String(JSON.parse(localStorage.getItem('token')))}`,
  //         'Content-Type': 'application/json',
  //       },
  //     });
      
  //     userData = userData?.data?.data;
  //     if (userData?.Role?.role == 'admin' || userData?.Role?.role == 'super_admin') {
  //       setUser(userData);
  //     } else {
  //       localStorage.removeItem('token');
  //       swal('error', 'Wrong credential !1', 'error'); 
  //       return <Navigate to="/login" />;
  //     }

  //   } catch (err) {
  //     localStorage.removeItem('token');
  //     return <Navigate to="/login" />;
  //   }
  // }

  async function getUserInfo() {
    try {
      let userData = await axios.get(`${baseUrl}/api/v1/auth/account`, {
        headers: {
          Authorization: `Bearer ${String(JSON.parse(localStorage.getItem('token')))}`,
          'Content-Type': 'application/json',
        },
      });
      userData = userData?.data?.data;
      if (userData?.Role?.role == 'admin' || userData?.Role?.role == 'super_admin') {
        auth.setUser(userData);
        return userData;
      } else {
        basicSwalAlert('error', 'Wrong credential !', 'error');
        localStorage.removeItem('token');
        return <Navigate to="/login" />;
      }
    } catch (err) {
      localStorage.removeItem('token');
      return <Navigate to="/login" />;
      //  null
    }
  }


  return children;
};
