import React from "react";
import { Snackbar, SnackbarOrigin } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

// export interface State extends SnackbarOrigin {
//   open: boolean;
// }
// type Props = {
//   message_err: string | null | undefined;
//   message_suc: string | null | undefined;
//   open: boolean;
//   handleClose(): void;
// };

const Alert = React.forwardRef(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PositionedSnackbar(props) {
  const color = props.message_suc !== "" ? "success" : "error";
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={props.open}
        onClose={props.handleClose}
        autoHideDuration={3000}
      >
        <Alert severity={color} sx={{ width: "100%", fontFamily: "Poppins" }}>
          {props.message_err !== "" ? props.message_err : props.message_suc}
        </Alert>
      </Snackbar>
    </div>
  );
}
