import React, { useState } from 'react';
import Button from '@mui/material/Button';
import {
  Box,
  Grid,
  DialogTitle,
  DialogActions,
  DialogContent} from '@mui/material';
import baseUrl from '../../utils/baseUrl';
import { Form, Formik } from 'formik';
import TextFieldBox from '../custom_fields/TextFieldBox';
import { toast } from 'react-hot-toast';



const UpdateHeader = (props,onCancel,onSnackBar) => {
  const [open, setOpen] = useState(false);


  const [message, setMessage] = useState({ error: false, msg: '' });

  const initialValues = {
    name: props['Header Name'],
    base_href: props['Base Path'],
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onCancel(true);
  };

  const handleSubmit = async (values) => {
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      },
      body: JSON.stringify(values),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/header_menu/${props.Id}`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        // setMessage({ error: false, msg: 'Header updated successfully!' });
        handleClose()
        onSnackBar({reload: true})
        toast.success(responseData.message || 'Header updated successfully!')
      } else {
        // setMessage({ error: true, msg: 'error occured' });
        toast.error(responseData.message || 'Error occured!')
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    }
  };


  return (
    <div>
      
        <DialogTitle>Update Header</DialogTitle>
        <DialogContent>
          <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={async (values) => {
            // console.log(values)
            await handleSubmit(values);
          }}
        >
          {({
            //@ts-ignore
            errors,
            values,
            //@ts-ignore
            touched,
          }) => (
            <Form>
            <Box p={3}>
              <Box width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextFieldBox
                      label="Header Name"
                      type="text"
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextFieldBox
                      label="Header Path"
                      type="text"
                      name="base_href"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button 
                type='submit'
              >Submit</Button>
            </DialogActions>
            </Form>
            )}
        </Formik>
        </DialogContent>
    </div>
  );
};

export default UpdateHeader;
