import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import {
  MenuItem,
  Box,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Divider,
  Alert,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Chip,
} from '@mui/material';
import Stack from '@mui/material/Stack';
// From firebase
import { getFirestore, collection, doc, getDoc, getDocs, setDoc, query } from 'firebase/firestore';
import { app } from '../../firebaseConfig';
import Iconify from '../../components/iconify';
import baseUrl from '../../utils/baseUrl';
import { Form, Formik } from 'formik';
import TextFieldBox from '../custom_fields/TextFieldBox';
import DropDownBox from '../custom_fields/DropDownBox';


const INSERT_ADVERTISE_OBJ = {
  page_name: '',
  location_name: '',
  location_info: '',
  length: '',
  width: '',
  height: '',
  status: 'active',
};
//  Note : Possible value : [“active“,”pending”,”deactive”]
// 	[Default : active]

const UpdateAdvertiseEntity = (props,onCancel) => {
  const [open, setOpen] = useState(false);
  const [subs, setSubs] = useState([]);
  const [coun, setCoun] = useState([]);
  const [statusAllArr, setStatus] = useState([]);
  // const [trans, setTrans] = useState([]);
  const [inputs, setInputs] = useState({
    page_name: props['Page Name'],
    location_name: props['Location Name'],
    location_info: '',
    length: props.Length,
    width: props.length,
    height: props.Height,
    status: props.Status,
  });
  const [selectedFieldItemNow, setSelectedFieldItem] = useState({});
  const [message, setMessage] = useState({ error: false, msg: '' });

  // **************[options UseState]*****************/
  const [optionsTxt, setOptionsTxt] = useState('');
  const [options, setOptions] = useState([]);
  const [showOptionField, setShowOptionField] = useState(false);

  const db = getFirestore(app);

  const fetchSubs = async () => {
    // const arr = [];
    // try {
    //   const data = await fetch(`${baseUrl}/api/v1/categories`);
    //   const arr = await data.json();
    //   console.log(arr?.data);
    //   setSubs(arr?.data?.allCategories);
    // } catch (error) {
    //   console.log(error);
    //   setSubs([]);
    // }
    // setSubs(arr);
  };
  const fetchCoun = async () => {
    const arr = [
      { value: 1,name: 'Home' },
      { value: 2,name: 'Listing' },
    ];
    // const arr = ['Home', 'Listing'];
    const arrStatus = [
      { id: 1, name: 'active' },
      { id: 2, name: 'pending' },
      { id: 3, name: 'deactive' },
    ];
    // const q = query(collection(db, 'countries'));
    // const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    //   // doc.data() is never undefined for query doc snapshots
    //   // console.log("Subject Names -----", doc.id, '=>', doc.data());
    //   arr.push(doc.data());
    // });
    console.log("props.selectedFieldItem", props.selectedFieldItem)
    setCoun(arr);
    setStatus(arrStatus)
    setSelectedFieldItem((old) => { return { ...old, ...props.selectedFieldItem } });
    setInputs({
      page_name: props['Page Name'],
      location_name: props['Location Name'],
      location_info: props.location_info,
      length: props.Length,
      width: props.length,
      height: props.Height,
      status: props.Status,
    })
  };
  // const fetchTrans = async () => {
  //   const arr = [];
  //   const q = query(collection(db, 'transcription'));
  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach((doc) => {/
  //     // doc.data() is never undefined for query doc snapshots
  //     // console.log("transcription -----", doc.id, '=>', doc.data());
  //     arr.push(doc.data());
  //   });
  //   setTrans(arr);
  // };
  // console.log(props)

  /** *********************[useEffect]*********************** */
  useEffect(() => {
    fetchCoun();
    fetchSubs();
    // fetchTrans();
  }, []);
  useEffect(() => {
    if (['selectBox', 'checkboxMulti', 'radio'].includes(inputs.type_of_field)) {
      setOptions([]);
      setShowOptionField(true);
    } else {
      setOptions([]);
      setShowOptionField(false);
    }
  }, [inputs]);
  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // let newName = [];
    // let keyWords = null;
    // if (event.target.name === 'name' || event.target.name === 'type_of_field') {
    //   newName = event.target.value.split(' ');
    //   newName.forEach((item) => {
    //     keyWords = {
    //       [item]: true,
    //     };
    //   });
    //   setInputs((values) => ({ ...values, [name]: value, ...keyWords }));
    // } else {
    //   setInputs((values) => ({ ...values, [name]: value }));
    // }
    await setInputs((values) => ({ ...values, [name]: value }));
    console.log('input', inputs, name, value);

    // **************[options UseState]*****************/
  };

  // **************[options UseState]*****************/
  const handleAddOption = () => {
    if (optionsTxt) {
      console.log('event------------->', optionsTxt, options);
      setOptions([...options, optionsTxt]);
      setOptionsTxt('');
    }
  };
  const handleAddOptionTxt = (event) => {
    console.log('event------------->', event.target.value);
    setOptionsTxt(event.target.value);
  };
  const handleDeleteOptions = (event) => {
    console.log('event------------->', event);
    const optionsList = options.filter((name) => name !== event);
    setOptions(optionsList);
  };
  // const handleAddMoreChange = (index, event) => {
  //   const values = [...addMoreInput];
  //   values[index][event.target.name] = event.target.value;
  //   if (event.target.name === 'trans_id') {
  //     values[index].name = trans.find((item) => item.id === event.target.value).name;
  //   }
  //   setAddMoreInput(values);
  // };
  // const handleClickMore = () => {
  //   setAddMoreInput([...addMoreInput, {}]);
  //   console.log(addMoreInput);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    onCancel(true)
  };

  // const myArr = 'How are you?'
  // const myArrSplit = myArr.split(' ');
  // console.log( myArrSplit[1]);

  // const myArr = (trans.split(' '))
  // console.log( myArr);

  const handleSubmit = async (values) => {
    values.use_in_weekly_email = values.use_in_weekly_email === "Yes" ? true : false;
    values.on_desktop_view = values.on_desktop_view === "Yes" ? true : false;
    values.on_mobile_view = values.on_mobile_view === "Yes" ? true : false;
    
    // setInputs('');
    // setAddMoreInput(['']);

    // console.log(inputs);
    // if (!inputs.page_name || !inputs.location_name || !inputs.width || !inputs.height) {
    //   setMessage({ error: true, msg: 'Please fill all the required fields' });
    //   return;
    // }
    // const postObjData = {
    //   //   name: inputs?.url,
    //   //   type: inputs?.type_of_field,
    //   //   categoryId: inputs?.cat_id,
    //   //   fieldLength: '255',
    //   //   defaultValue: 'null',
    //   //   required: true,
    //   //   helpText: 'helpText',
    //   //   useAsFilter: checkedUseAsFilter,
    //   //   active: true,
    //   page_name: inputs.page_name,
    //   location_name: inputs.location_name,
    //   location_info: inputs.location_info,
    //   length: inputs.width,
    //   height: inputs.height,
    //   status: inputs.status,
    // };
    const {page_name,location_name,email,name, ...payload} = values;
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/advertisement/entity/${props.Id}`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        setMessage({ error: false, msg: 'Advertisement entity updated successfully!' });
        handleClose();
        // fetchVideo();
      } else {
        setMessage({ error: true, msg: 'error occured' });
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    }finally{
      handleClose()
    }
  };
  console.log(props)


  return (
    <>
      {message?.msg && (
        <Alert severity={message?.error ? 'error' : 'success'} dismissible={'true'} onClose={() => setMessage('')}>
          {message?.msg}
        </Alert>
      )}
      {/* <Button variant="outlined" startIcon={<i className="fa-solid fa-pen-to-square fa-xs"></i>} onClick={handleClickOpen}/> */}
      {/* <div onClick={handleClickOpen} className='cursor-pointer'><i className="fa-solid fa-pen-to-square text-[#203f74] text-base"></i></div> */}

      {/* <Dialog open={open} onClose={handleClose}> */}
        <DialogTitle>Update Advertise Entity</DialogTitle>
        <div>
          {/* <DialogContentText>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </DialogContentText> */}
          <Box>
          <Formik
          initialValues={{
          page_name: props['Page Name'],
          location_name: props['Location Name'],
          email: props['User Email'],
          // length: props.Length,
          name: props['User Name'],
          start_date_time: props['Start Time'],
          end_date_time: props['End Time'],
          type_of_add: props['Type Of Add'],
          use_in_weekly_email: props['Weekly Email'],
          on_desktop_view: props['Desktop View'],
          on_mobile_view: props['Mobile View'],
          status: props.Status,
          listing_id: props['List Id'],
          // price: props.Price
        }}
          // validationSchema={validationSchema}
          onSubmit={async (values) => {
            // console.log(values)
            await handleSubmit(values);
          }}
        >
          {({
            //@ts-ignore
            errors,
            values,
            //@ts-ignore
            touched,
          }) => (
            <Form>
            <Box p={3}>
              <Box width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {/* <TextField
                      select
                      size="small"
                      label="Page name"
                      variant="standard"
                      name="page_name"
                      value={inputs.page_name || ''}
                      onChange={handleChange}
                      required
                      fullWidth
                    >

                      {coun.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField> */}
                    <DropDownBox
                      label="Page name"
                      name="page_name"
                      options = {coun}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DropDownBox
                      label="Add Type"
                      name="type_of_add"
                      options = {[{name: 'text'},{name: 'infoGraphic'}]}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <TextField
                      select
                      size="small"
                      label="Type of Field"
                      variant="standard"
                      name="type_of_field"
                      value={inputs.type_of_field || ''}
                      onChange={handleChange}
                      helperText="Please select your field type"
                    >
                      {coun.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <TextFieldBox
                      label="Location Name"
                      type="text"
                      name="location_name"
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <TextFieldBox
                      label="User Email"
                      type="text"
                      name="email"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldBox
                      label="User Name"
                      type="text"
                      name="name"
                    />
                  </Grid> */}

                  {/* <Grid item xs={12} md={12}>
                    <span><b>Area Size</b></span>
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    {/* <TextField
                      // autoFocus
                      //   margin="dense"
                      id="url"
                      label="Advertise Width"
                      type="text"
                      name="width"
                      value={inputs.width || ''}
                      onChange={handleChange}
                      fullWidth
                      variant="standard"
                      required
                    /> */}
                    <TextFieldBox
                      label="Start Time"
                      type="text"
                      name="start_date_time"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {/* <TextField
                      // autoFocus
                      //   margin="dense"
                      id="url"
                      label="Advertise Height"
                      type="text"
                      name="height"
                      value={inputs.height || ''}
                      onChange={handleChange}
                      fullWidth
                      variant="standard"
                      required
                    /> */}
                    <TextFieldBox
                      label='End Time'
                      type="text"
                      name="end_date_time"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DropDownBox
                      label="Desktop View"
                      name="on_desktop_view"
                      options = {[{name: 'Yes', value: true},{name: 'No',value: false}]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DropDownBox
                      label="Mobile View"
                      name="on_mobile_view"
                      options = {[{name: 'Yes', value: true},{name: 'No',value: false}]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DropDownBox
                      label="Weekly Email"
                      name="use_in_weekly_email"
                      options = {[{name: 'Yes', value: true},{name: 'No',value: false}]}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {/* <TextField
                      // autoFocus
                      //   margin="dense"
                      select
                      label="Advertise Status"
                      type="text"
                      name="status"
                      value={inputs.status || ''}
                      onChange={handleChange}
                      fullWidth
                      variant="standard"
                    >
                      {statusAllArr.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField> */}
                    <DropDownBox
                      label="Status"
                      name="status"
                      options = {statusAllArr}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button 
                type='submit'
              // onClick={handleSubmit}
              >Submit</Button>
            </DialogActions>
            </Form>
            )}
        </Formik>
          </Box>
        </div>
      {/* </Dialog> */}
    </>
  );
};

export default UpdateAdvertiseEntity;
