import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
  Stack,
  Container,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { getFirestore } from 'firebase/firestore';
import DataGrid from '../components/Datagrid/Datagrid';
import { app } from '../firebaseConfig';
import baseUrl from '../utils/baseUrl';
import AddAdvertise from '../modules/advertise/AddAdvertise';
import Component from 'src/modules/advertise/UpdateAdvertise';
import PositionedSnackbar from 'src/components/Snackbar/Snackbar';


export default function AdvertisePage() {
  const [open, setOpen] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [checked, setChecked] = useState({});
  const [allAddAdvertise, setAllAddAdvertise] = useState([]);
  const [allFilterArr, setAllFilterArr] = useState([]);
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false);
  const [sucMsg, setSucMsg] = useState("")
  const [errMsg, setErrMsg] = useState("")

  const [inputs, setInputs] = useState({
    search_item: '',
    type_selection: 'page_name',
  });
  const [placeholderTxt, setPlaceholderTxt] = useState('Enter page name');
  const db = getFirestore(app);

  useEffect(() => {
    fetchVideo();
  }, [reload]);

  const fetchVideo = async () => {
    const arr = [
      { name: 'Search by id', value: 'id' },
      { name: 'Search by Page Name', value: 'page_name' },
      { name: 'Search by Location', value: 'location_name' },
    ];
    setAllFilterArr(arr);
    const checkedObj = {};
    try {
      setLoading(true);
      const data = !inputs.search_item
        ? await fetch(`${baseUrl}/api/v1/advertisement/location`)
        : await fetch(`${baseUrl}/api/v1/advertisement/location?${inputs.type_selection}=${inputs.search_item}`);
      const arr = await data.json();
      const modifiedData = arr?.data?.map((i) => {
        return {
          ...i,
        };
      });
      const modifiedDataSort = modifiedData.sort((a,b)=>a.id -b.id)
      await setChecked(checkedObj);
      await setAllFields([...modifiedDataSort]);
      await setAllAddAdvertise([...modifiedDataSort]);
    } catch (error) {
      // console.log(error);
      await setAllFields([]);
    } finally {
      setLoading(false);
    }

    // // console.log(checked);
  }


  // const fetchVideo = async () => {
  //   try {
  //     const data = await fetch(`${baseUrl}/api/v1/listings`);
  //     const arr = await data.json();
  //     setAllFields(arr?.listings);
  //   } catch (error) {
  //     // console.log(error);
  //     setAllFields([]);
  //   }
  // };
  console.log(allFields)

  // const Component = ()=>(<UpdatedAdvertise/>)

  const onClickEvent = ()=>{

  }
  const snackBarHandler = (data)=>{
    console.log(data)
    setOpen(data.open);
    setErrMsg(data.errMsg);
    setSucMsg(data.sucMsg);
    setReload(data.reload);
  }
  const reloadHandler = (data)=>{
    setReload(data)
  }
  const handleClose = ()=>{
    setOpen(!open);
  }

  // const handleOpenMenu = (event) => {
  //   setOpen(event.currentTarget);
  // };
  // const handleCloseMenu = () => {
  //   setOpen(null);
  // };

  const FilteredData = allFields.map((field, key) => {
    return {
      Code: field.id,
      Id: field.id,
      'Page Name': field.page_name,
      'Location Name': field.location_name,
      Length: field.length,
      Height: field.height,
      'Location Info': field.location_info,
      Price: field.price,
      // Pricing: field.pricing,
      // OpeningTime: field.openingTime,
      // ClosingTime: field.closingTime,
      Status: field.status
    }
  })
  return (
    <div>
      <Helmet>
        <title> Advertise </title>
      </Helmet>
      {/* <PositionedSnackbar
          open={open}
          message_err={errMsg}
          message_suc={sucMsg}
          handleClose={handleClose}
        /> */}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Advertise
          </Typography>
          <AddAdvertise fetchVideo={fetchVideo} onSnackBar={snackBarHandler}/>
        </Stack>

        {FilteredData?.length> 0 ? <DataGrid
            data={FilteredData}
            onClickEvent={onClickEvent}
            checkBox
            pagingTool
            hideTool
            isFilter
            empGrid
            wrap
            name="/api/v1/advertisement/location/"
            componentName="editExpenceForm"
            viewName="viewExpenseForm"
            openSnackBar = {snackBarHandler}
            // fetchVideo={fetchVideo}
            renderComponent = {Component}
            reload = {reloadHandler}
        /> : loading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}><CircularProgress /> &nbsp;Loading....</Box>
        : <h1 className="text-base text-center font-semibold">Data Not Found!!</h1>}
      </Container>
    </div>
  );
}
