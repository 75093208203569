import React, { useState, useEffect } from 'react';
import {
  Card,
  Typography,
  Grid,
  IconButton,
  Popover,
  MenuItem,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Switch,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import { getFirestore } from 'firebase/firestore';
import { app } from '../../firebaseConfig';
import Iconify from '../../components/iconify';
import baseUrl from '../../utils/baseUrl';
import AddSubCategories from './AddSubCategories';
import { LoadingButton } from '@mui/lab';
import swal from 'sweetalert';
import { toast } from 'react-hot-toast';

const SubCategories = () => {
  const [subs, setSubs] = useState([]);
  const [open, setOpen] = useState(null);
  const [categories, setCategories] = useState([]);
  const [openedit, setOpenEdit] = useState(null);
  const db = getFirestore(app);
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = useState({ name: '' });
  const [selectedSubCategory, setselectedSubCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [statusChecked, setStatusChecked] = useState({});
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setValue({ ...value, name: selectedSubCategory?.name });
  }, [selectedSubCategory]);

  const handleActiveSwitchChange = async (event, switchID) => {
    setStatusChecked({ ...statusChecked, [switchID]: !statusChecked[switchID] });

    const patchData = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        Authorization: JSON.parse(localStorage.getItem('token')),
      },
      body: JSON.stringify({
        status: !!statusChecked[switchID] ? 'deactive' : 'active',
      }),
    };

    try {
      const data = fetch(`${baseUrl}/api/v1/subCategories/${switchID}`, patchData);
      // const responseData = await data.json();
      if (data) {
        toast.success('Sub-Category updated successfully');
      }
    } catch (error) {
      console.log(error);
      toast.error('Error updating sub-category');
    }
  };

  const handleOpenMenu = (event, item) => {
    setOpen(event.currentTarget);
    setselectedSubCategory(item);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setOpen(false);
  };

  const fetchCategories = async () => {
    // const arr = [];
    try {
      const data = await fetch(`${baseUrl}/api/v1/categories?status=active`);
      const arr = await data.json();
      console.log(arr?.data);
      setCategories(arr?.data?.allCategories);
    } catch (error) {
      console.log(error);
      setCategories([]);
    }
  };

  const fetchSubs = async () => {
    const checkedObj = {};

    try {
      const data = await fetch(`${baseUrl}/api/v1/subCategories`);
      const arr = await data?.json();
      arr?.data?.subCategories.map((i) => {
        checkedObj[i?.id] = i?.status === 'active' ? true : false;
      });
      setStatusChecked(checkedObj);
      setSubs(arr?.data?.subCategories);
    } catch (error) {
      console.log(error);
      setSubs([]);
    }
  };
  useEffect(() => {
    fetchSubs();
    fetchCategories();
  }, []);

  const subCategoryDelete = async (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to Delete?',
      icon: 'warning',
      // buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setLoading(true);

        const requestOptions = {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: String(JSON.parse(localStorage.getItem('token'))),
          },
        };
        try {
          const data = await fetch(`${baseUrl}/api/v1/subCategories/${id}`, requestOptions);
          const resolvedData = await data.json();
          console.log(resolvedData);
          if (resolvedData) {
            // setMessage({ error: false, msg: 'Deleted Successfully!' });
            toast.success('Deleted Successfully!');
            fetchSubs();
            setOpen(false);
            handleCloseEdit();
          } else {
            setMessage({ error: true, msg: 'error occured' });
          }
        } catch (error) {
          console.log(error);
          setMessage({ error: true, msg: 'error occured' });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  // const [value, setValue] = useState({ name: '' });
  const [message, setMessage] = useState({ error: false, msg: '' });

  const handleValue = (event) => {
    const name = event.target.name;
    const val = event.target.value;
    setValue((values) => ({ ...values, [name]: val }));
  };

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    setLoading(true);

    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(JSON.parse(localStorage.getItem('token'))),
      },
      body: JSON.stringify({
        name: value.name,
        // icon: 'icon',
        // active: true,
      }),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/subCategories/${id}`, requestOptions);
      const resolvedData = await data.json();
      console.log(resolvedData);
      if (resolvedData) {
        // setMessage({ error: false, msg: 'Updated Successfully!' });
        toast.success('Updated Successfully!');
        fetchSubs();
        setOpen(false);
        handleCloseEdit();
      } else {
        setMessage({ error: true, msg: 'error occured' });
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    } finally {
      setLoading(false);
    }
    // console.log(selectedSubCategory)
  };

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Sub-categories
        </Typography>
        <AddSubCategories fetchSubs={fetchSubs} categoryData={categories} />
      </Stack>
      {categories.map((category, index) => (
        <Accordion
          expanded={expanded === `panel${category.id}`}
          onChange={handleChange(`panel${category.id}`)}
          key={index}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography
              sx={{
                width: '33%',
                flexShrink: 0,
                padding: '6px',
                whiteSpace: 'nowrap',
                fontWeight: '700',
                fontSize: '16px',
              }}
            >
              {category.name}
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {subs?.map((item, index) => (
                <>
                  {item.categoryId === category.id && (
                    <Grid key={index} item xs={12} sm={6} md={3}>
                      <Card>
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          spacing={2}
                          sx={{ p: 3 }}
                        >
                          <Typography variant="subtitle2" noWrap>
                            {item.name}
                          </Typography>
                          <Stack direction={'row'} alignItems={'center'}>
                            <IconButton size="small" color="inherit" onClick={(event) => handleOpenMenu(event, item)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                            <Switch
                              inputProps={{ 'aria-label': 'controlled' }}
                              color="primary" // defaultChecked
                              checked={statusChecked[item.id]}
                              onChange={(event) => handleActiveSwitchChange(event, item.id)}
                            />
                          </Stack>
                        </Stack>
                      </Card>
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 100,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenEdit(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        {/* <MenuItem onClick={(e) => subCategoryDelete(selectedSubCategory?.id)}>
          <Iconify icon={'eva:close-fill'} sx={{ mr: 2 }} />
          Remove
        </MenuItem> */}
      </Popover>
      <Dialog open={openedit} onClose={handleCloseEdit} maxWidth={'xs'} fullWidth>
        <DialogTitle>Edit Sub Category</DialogTitle>
        {selectedSubCategory && (
          <DialogContent>
            <TextField
              variant="filled"
              color="info"
              fullWidth
              label="Name"
              name="name"
              value={value?.name || ''}
              onChange={handleValue}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCloseEdit} variant="outlined">
            Cancel
          </Button>
          {/* <Button onClick={(e) => handleSubmit(e, selectedSubCategory?.id)} variant='outlined' className='bg-blue-800 text-white hover:bg-blue-700'>Edit Sub Category</Button> */}
          <LoadingButton
            type="submit"
            loading={loading}
            onClick={(e) => handleSubmit(e, selectedSubCategory?.id)}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
          >
            <span>Edit Sub Category</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SubCategories;
