import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select from 'react-select';
import Button from '@mui/material/Button';
import { Box, Grid, DialogTitle, DialogActions, CircularProgress } from '@mui/material';
import baseUrl from '../../utils/baseUrl';
import { Form, Formik } from 'formik';
import TextFieldBox from '../custom_fields/TextFieldBox';
import DropDownBox from '../custom_fields/DropDownBox';
import useCountry from 'src/utils/useCountry';
import useCategories from 'src/utils/useCategories';
import useTagList from 'src/utils/useTagList';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import PositionedSnackbar from 'src/components/Snackbar/Snackbar';
import { toast } from 'react-hot-toast';
import { useAuth } from 'src/utils/services/auth/authService';

const UpdateGroups = (props, onCancel, onSnackBar) => {
  const [open, setOpen] = useState(false);
  const [sucMsg, setSucMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [coun, setCoun] = useState([]);
  const [statusAllArr, setStatus] = useState([]);
  const [selectedTag, setseletedTag] = useState(
    props?.tags?.map((item) => ({ id: item.tag_id, tag: item.tags.tag })) || []
  );
  const tagInput = useRef(null);
  const [tagList, setTagList, loadingState] = useTagList(searchKey);

  const [countries] = useCountry(null);
  const [listCategories] = useCategories();
  const [selectedState, setSelectedState] = useState({
    label: '',
    value: '',
  });

  const auth = useAuth();

  const fetchSubs = async () => {};
  const fetchCoun = async () => {
    const arr = [
      { value: 1, name: 'Home' },
      { value: 2, name: 'Listing' },
    ];
    const arrStatus = [
      { id: 1, name: 'active' },
      { id: 2, name: 'pending' },
      { id: 3, name: 'deactive' },
    ];

    setCoun(arr);
    setStatus(arrStatus);
  };

  const handleAddTagInDb = async () => {
    try {
      setLoading(true);
      const url = `${baseUrl}/api/v1/disscussion-tags`;
      const payload = { tag: tagInput.current.value };

      const data = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const resData = await data.json();
      if (resData.data) {
        setseletedTag((prev) => [...prev, resData.data]);
        tagInput.current.value = ''; //clear the form
      } else {
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleTagSelect = (tag) => {
    for (let i = 0; i < selectedTag.length; i++) {
      if (selectedTag[i].id == tag.id) {
        // toast.error("Tag selected already");
        return false;
      }
    }
    setseletedTag((prev) => [...prev, tag]);
    setTagList([]);
    tagInput.current.value = ''; //clear the form
  };

  const handleRemoveTag = (id) => {
    setseletedTag((previ) => previ.filter((item) => item.id !== id));
  };

  const changeHandler = async (event) => {
    await setSearchKey(event.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 800), []);
  // after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  useEffect(() => {
    fetchCoun();
    fetchSubs();
  }, []);

  useEffect(() => {
    auth.getCityList(selectedState.value);
  }, [selectedState]);

  const handleClose = () => {
    onCancel(true);
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const url = `${baseUrl}/api/v1/groups/room/${props.Code}`;
      values.tags = selectedTag.map((tag) => (tag = tag.id));
      const { room_name, room_type, ...payload } = values;
      const data = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        },
        body: JSON.stringify(payload),
      });
      const resData = await data.json();
      console.log(resData);
      if (resData.data) {
        resData.data &&
          onSnackBar({
            open: true,
            errMsg: '',
            sucMsg: 'Group updated successfully',
            reload: true,
          });
        toast.success('Group updated successfully!');
        handleClose();
        // formik.resetForm();
        setseletedTag([]);
      } else {
        setOpen(true);
        setSucMsg('');
        setErrMsg(resData.data.errors.message);
        toast.error(resData.data.errors.message || 'Error Occured!');
      }
    } catch (error) {
      if (error.message == 'unAuthorized') {
        onSnackBar({
          open: true,
          sucMsg: '',
          errMsg: 'Please log in before create an discussion',
        });
      } else {
      }
    } finally {
      setLoading(false);
    }
  };
  console.log(props);
  const handleCancel = () => {
    setOpen(!open);
  };

  return (
    <>
      <PositionedSnackbar open={open} message_err={errMsg} message_suc={sucMsg} handleClose={handleCancel} />
      <DialogTitle>Update Group</DialogTitle>
      <div>
        <Box>
          <Formik
            initialValues={{
              room_name: props['Room Name'],
              room_type: 'public',
              location: props?.Location,
              // meta_title: '',
              tags: selectedTag.map((tag) => (tag = tag.id)), //only send ids
              // content: props?.content,
              categoryId: props.category,
            }}
            // validationSchema={validationSchema}
            onSubmit={async (values) => {
              console.log(values);
              await handleSubmit(values);
            }}
          >
            {({
              //@ts-ignore
              errors,
              values,
              setFieldValue,
              //@ts-ignore
              touched,
            }) => (
              <Form>
                <Box p={3}>
                  <Box width="100%" component="form" method="post" autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextFieldBox
                          label="Group Name"
                          name="room_name"
                          //   options = {coun}
                          type="text"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <DropDownBox
                          label="Group Type"
                          name="room_type"
                          options={[{ name: 'public' }]}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={}
                            placeholder="Select State"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                background: '#fff',
                                borderTop: 'none',
                                borderRight: 'none',
                                borderLeft: 'none',
                                borderColor: '#cccccc',
                                borderRadius: 'none',
                                minHeight: '38px',
                                height: '38px',
                                boxShadow: state.isFocused ? null : null,
                              }),
                            }}
                            labelledBy="Select"
                            value={selectedState.value === '' ? null : selectedState}
                            onChange={(e) => setSelectedState(e)}
                            options={auth.stateList}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            // defaultValue={colourOptions[0]}
                            placeholder="Select City"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                background: '#fff',
                                // borderColor: values[`location`] ? 'red' : '#cccccc',
                                borderTop: 'none',
                                borderRight: 'none',
                                borderLeft: 'none',
                                borderColor: '#cccccc',
                                borderRadius: 'none',
                                minHeight: '38px',
                                height: '38px',
                                boxShadow: state.isFocused ? null : null,
                              }),
                            }}
                            isSearchable={true}
                            onChange={(e) =>
                              setFieldValue('location', e.value + ',' + selectedState.value + ',' + 'USA')
                            }
                            labelledBy="Select"
                            options={auth.cityList}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <DropDownBox
                          label="Categories"
                          name="categoryId"
                          options={listCategories.map((category) => ({ id: category.id, name: category.name }))}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="flex flex-col">
                          <label>Discussion Tags</label>
                          <div className="">
                            {selectedTag?.length > 0 && (
                              <div className="">
                                {selectedTag.map((tag) => (
                                  <span key={tag.id} className="chip badge badge-pill badge-info border rounded px-1">
                                    {tag.tag}
                                    <span
                                      style={{
                                        padding: '0 0px 0 10px',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => handleRemoveTag(tag.id)}
                                    >
                                      <FontAwesomeIcon icon={faXmark} />
                                    </span>
                                  </span>
                                ))}
                              </div>
                            )}
                            <input
                              type="text"
                              className="w-full border outline-none p-[0.65rem] rounded focus:bg-gray-50"
                              placeholder="Exp: Keyword1"
                              name="listingTitle"
                              autoComplete="off"
                              ref={tagInput}
                              onChange={debouncedChangeHandler}
                            />
                            {loadingState && (
                              <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                              </Box>
                            )}
                            {tagList?.length > 0 && (
                              <ul className="border pl-2 rounded">
                                {tagList.map((tag) => (
                                  <li key={tag.id} className="cursor-pointer">
                                    <span onClick={() => handleTagSelect(tag)}>{tag.tag}</span>
                                  </li>
                                ))}
                              </ul>
                            )}
                            {tagList?.length == 0 && tagInput.current?.value && !loadingState && (
                              <Button type="button" onClick={handleAddTagInDb} className="btn btn-light mt-1">
                                Click to Add
                              </Button>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <DialogActions sx={{ padding: 3 }}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    // onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Box>
      </div>
    </>
  );
};

export default UpdateGroups;
