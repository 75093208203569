/* eslint-disable arrow-body-style */
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import baseUrl from '../../baseUrl';
import { useNavigate, Navigate } from 'react-router-dom';
import { basicSwalAlert } from '../../../utils/services/swal.service';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [accessToken, setAccessToken] = useState(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZXJfZW1haWwiOiJzYXJvanRoYWt1cjI0N0BnbWFpbC5jb20iLCJhcGlfdG9rZW4iOiI2bzY5OHY1ejFGM1h1YjFTZEk4eGcxM2NpcmFGalQ4S1RmOTZESmVqR3hKM3p0UDlHT3ZEbkQ2bzFGakxINElOa01rIn0sImV4cCI6MTY3ODE3MzMyMn0.XsRChPH6pHONKCt2klcM6424YW2NGTEOjKSlBP0iKHU'
  );

  const getNewAccessToken = async () => {
    try {
      const data = await axios({
        method: 'GET',
        url: 'https://www.universal-tutorial.com/api/getaccesstoken',
        headers: {
          Accept: 'application/json',
          'api-token': '6o698v5z1F3Xub1SdI8xg13ciraFjT8KTf96DJejGxJ3ztP9GOvDnD6o1FjLH4INkMk',
          'user-email': 'sarojthakur247@gmail.com',
        },
      });

      // console.log("new Token data", data?.data?.auth_token);

      setAccessToken(data?.data?.auth_token);
      // getAllCountries();
    } catch (error) {
      console.log(error);
    }
  };

  const getStateList = async (country) => {
    try {
      const data = await axios({
        method: 'get',
        url: `https://www.universal-tutorial.com/api/states/${'United States'}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
      });
      const stateOptions = data?.data.map((i) => ({
        value: i.state_name,
        label: i.state_name,
      }));
      setStateList(stateOptions);
    } catch (error) {
      // alert("error", error);
      getNewAccessToken();
    }
    // try {
    //   const data = await fetch(
    //     `${ApiBaseUrl}/api/v1/list/country?country_id=${233}`
    //   );
    //   const arr = await data.json();
    //   const state = arr?.data?.map((value) => ({
    //     value: value.name,
    //     label: value.name,
    //   }));
    //   setStateList(state);
    // } catch (error) {
    //   console.log(error);
    //   toast.error("Someting went wrong on data fetch, Please try again");
    // }
  };

  const getCityList = async (state_name) => {
    try {
      const data = await axios({
        method: 'get',
        url: `https://www.universal-tutorial.com/api/cities/${state_name}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
      });
      const cityOptions = data?.data.map((i) => ({
        value: i.city_name,
        label: i.city_name,
      }));
      // const cityOptions = data?.data.map((i) => i.city_name);

      setCityList(cityOptions);
    } catch (error) {
      // alert("error", error);
      getNewAccessToken();
    }
  };

  async function getUserInfo() {
    try {
      let userData = await axios.get(`${baseUrl}/api/v1/auth/account`, {
        headers: {
          Authorization: `Bearer ${String(JSON.parse(localStorage.getItem('token')))}`,
          'Content-Type': 'application/json',
        },
      });
      userData = userData?.data?.data;
      if (userData?.Role?.role == 'admin' || userData?.Role?.role == 'super_admin') {
        setUser(userData);
        return userData;
      } else {
        throw new Error('some message');
      }
    } catch (err) {
      console.log(err + '1234');
      throw new Error('some message');
      //  null
    }
  }

  const login = async (email, password) => {
    return axios
      .post(`${baseUrl}/api/v1/auth/login`, {
        email,
        password,
      })
      .then(async ({ data }) => {
        if (data.data?.token) {
          const fetchUser = JSON.parse(atob(data.data.token.split('.')[1]));
          localStorage.setItem('token', JSON.stringify(data.data.token));
          await getUserInfo().then(
            (res) => {
              // basicSwalAlert('Good job!', 'You have Successfully Login!', 'success');
            },
            (err) => {
              localStorage.removeItem('token');
              basicSwalAlert(
                'error',
                "You don't have correct permission to perform this action, contact to your admin ! ",
                'error'
              );
              return <Navigate to="/login" />;
            }
          );
        }
        return data;
      })
      .catch((e) => {
        swal('error', 'Wrong credential !', 'error');
      });
  };

  const logout = () => {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to Logout?',
      icon: 'warning',
      // buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        localStorage.removeItem('token');
        setUser(null);
      }
    });
  };
  useEffect(() => {
    getStateList();
  }, [accessToken]);

  return (
    <AuthContext.Provider value={{ user, login, logout, setUser, stateList, cityList, getCityList }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
  // return {user, login, logout, setUser}
};
