import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import Categories from '../modules/categories/Categories';

export default function CategoriesPage() {
  return (
    <>
      <Helmet>
        <title> Categories </title>
      </Helmet>
      <Container>
        <Categories />
      </Container>
    </>
  );
}
