import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import {
  Stack,
  Container,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import DataGrid from '../components/Datagrid/Datagrid';
import baseUrl from '../utils/baseUrl';
import Component from 'src/modules/Header/UpdateHeader';
import AddBanner from 'src/modules/Banner/AddBanner';
import PositionedSnackbar from 'src/components/Snackbar/Snackbar';


export default function BannerPage() {
  const [open, setOpen] = useState(false);
  const [sucMsg, setSucMsg] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const [allFields, setAllFields] = useState([]);
  const [checked, setChecked] = useState({});
  const [allAddAdvertise, setAllAddAdvertise] = useState([]);
  const [allFilterArr, setAllFilterArr] = useState([]);
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchVideo();
  }, [reload]);

  const fetchVideo = async () => {
    const arr = [
      { name: 'Search by id', value: 'id' },
      { name: 'Search by Page Name', value: 'page_name' },
      { name: 'Search by Location', value: 'location_name' },
    ];
    setAllFilterArr(arr);
    const checkedObj = {};
    try {
      setLoading(true);
      const data = 
        await fetch(`${baseUrl}/api/v1/slide_banner`)
      const arr = await data.json();
      const modifiedData = arr?.data?.bannerImages?.map((i) => {
        return {
          ...i,
        };
      });
      const modifiedDataSort = modifiedData.sort((a,b)=>a.id -b.id)
      await setChecked(checkedObj);
      await setAllFields([...modifiedDataSort]);
      await setAllAddAdvertise([...modifiedDataSort]);
    } catch (error) {
      await setAllFields([]);
    } finally {
      setLoading(false);
    }
  }

  const onClickEvent = ()=>{

  }
  const snackBarHandler = (data)=>{
    console.log(data)
    setOpen(data.open);
    setErrMsg(data.errMsg);
    setSucMsg(data.sucMsg);
    // setReload(data.reload);
    data.reload && fetchVideo()
  }
  const reloadHandler = (data)=>{
    setReload(data)
  }

  const handleClose = ()=>{
    setOpen(!open);
  }
  const FilteredData = allFields?.map((field, key) => {
    return {
      Code: field.id,
      Id: field.id,
      'Image': field.banner_img,
      Status: field.status
    }
  })
  return (
    <div>
      <Helmet>
        <title> Banner Images </title>
      </Helmet>
      {/* <PositionedSnackbar
          open={open}
          message_err={errMsg}
          message_suc={sucMsg}
          handleClose={handleClose}
        /> */}

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Banner Images
          </Typography>
          <AddBanner fetchVideo={fetchVideo} onSnackBar={snackBarHandler}/>
        </Stack>

        {FilteredData?.length> 0 ? <DataGrid
            data={FilteredData}
            onClickEvent={onClickEvent}
            checkBox
            pagingTool
            hideTool
            isFilter
            empGrid
            wrap
            name="/api/v1/slide_banner/"
            // componentName="editExpenceForm"
            // viewName="viewExpenseForm"
            openSnackBar = {snackBarHandler}
            // fetchVideo={fetchVideo}
            renderComponent = {Component}
            // reload = {reloadHandler}
        /> : loading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}><CircularProgress /> &nbsp;Loading....</Box>
        : <h1 className="text-base text-center font-semibold">Data Not Found!!</h1>}
      </Container>
    </div>
  );
}

