import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import VideosPage from './pages/VideosPage';
import UsersPage from './pages/UsersPage';
import LoginPage from './pages/LoginPage';
import Error404Page from './pages/Error404Page';
import CategoriesPage from './pages/CategoriesPage';
import SubcategoriesPage from './pages/SubcategoriesPage';
import DashboardPage from './pages/DashboardPage';
import CustomFieldPage from './pages/CustomFieldPage';
import Listings from './pages/Listings';
// import PlayVideo from './modules/videos/PlayVideo';
// import CountriesPage from './pages/CountriesPage';
// import TranscriptionPage from './pages/TranscriptionPage';
import AdvertisePage from './pages/AdvertisePage';
import AdvertiseentityPage from './pages/AdvertiseentityPage';
import {RequireAuth} from './utils/services/auth/RequireAuth';
import CityPrice from './pages/City Price/CityPrice';
import BannerImage from './pages/BannerImage';
import Discussions from './pages/Discussions';
import Groups from './pages/Groups';
import HeaderPage from './pages/HeaderPage';
import BannerPage from './pages/BannerPage';

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <RequireAuth>
          
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardPage /> },
        { path: 'users', element: <UsersPage /> },
        { path: 'listings', element: <Listings /> },
        { path: 'categories', element: <CategoriesPage /> },
        { path: 'subcategories', element: <SubcategoriesPage /> },
        { path: 'customfields', element: <CustomFieldPage /> },
        { path: 'advertise', element: <AdvertisePage /> },
        { path: 'advertiseentity', element: <AdvertiseentityPage /> },
        { path: 'cityprice', element: <CityPrice /> },
        { path: 'bannerimageupload', element: <BannerPage /> },
        { path: 'discussion', element: <Discussions /> },
        { path: 'groups', element: <Groups /> },
        { path: 'headermenu', element: <HeaderPage />},
        
      ],
    },
    // { path: 'playvideo', element: <PlayVideo /> },
    { path: 'login', element: <LoginPage /> },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Error404Page /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);

  return routes;
}
