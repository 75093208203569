/* eslint-disable */
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// import "firebase/auth";
// import "firebase/firestore";
// import "firebase/storage";
// import "firebase/database";

export const firebaseConfig = {
  apiKey: "AIzaSyD_SyRadLdu43dRifZI4OVoeRoMBqNKtnQ",
  authDomain: "cakeyum-195df.firebaseapp.com",
  databaseURL: "https://cakeyum-195df-default-rtdb.firebaseio.com",
  projectId: "cakeyum-195df",
  storageBucket: "cakeyum-195df.appspot.com",
  messagingSenderId: "121711656682",
  appId: "1:121711656682:web:9d2ad77bc3e15814af6193",
  measurementId: "G-LQKT518936"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
// export const auth = firebase.auth();
// export const database = firebase.database();
// export const firestore = firebase.firestore();
// export const storage = firebase.storage();
