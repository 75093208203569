import React, { useState } from 'react';
import Button from '@mui/material/Button';
import {
  Box,
  Alert,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent} from '@mui/material';
// From firebase
import Iconify from '../../components/iconify';
import baseUrl from '../../utils/baseUrl';
import { Form, Formik } from 'formik';
import DropDownBox from '../custom_fields/DropDownBox';
import BannerImage from 'src/pages/BannerImage';
import { toast } from 'react-hot-toast';

const AddBanner = ({ fetchVideo,onSnackBar }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({ error: false, msg: '' });
  const [images, setImages] = useState([]);

  const arrStatus = [
    { name: 'active' },
    { name: 'pending' },
    { name: 'deactive' },
  ];
  const uploadOnS3Bucket = async (acceptedFile) => {
    const data = await fetch(`${baseUrl}/api/v1/s3test`)
      .then((res) => res.json())
      .then((data) => {
        fetch(data?.data?.bucketUrl, {
          method: "PUT",
          headers: {
            "Content-Type": acceptedFile?.type,
          },
          body: acceptedFile,
        })
          .then(() => (
            data?.data?.bucketUrl?.split("?")[0]
            // ,toast.success(`${acceptedFile.name} uploaded successfully`)

          ))
          .catch((e) => (console.log("error" + e)
          // , toast.error(`Something wrong during uploading ${acceptedFile?.name}!!`)
          ));
        return data?.data?.bucketUrl?.split("?")[0];
      });
    return data;
  };

  const uploadHandler = async (values) => {
    let Gallery = [];
    images?.forEach(async (image) => {
      let img_url = await uploadOnS3Bucket(image);
      console.log(img_url);
      Gallery.push(img_url);
      values.banner_img = Gallery
      // console.log(values.banner_img)
      images.length === Gallery.length && finalListSubmit(values)
    });
  };
  const finalListSubmit = async (values) => {
    values.banner_img = values.banner_img[0]
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      },
      body: JSON.stringify(values),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/slide_banner`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        setTimeout(()=> {handleClose()},1000);
        // handleClose()
        onSnackBar({
          open: true,
          errMsg: "",
          sucMsg: responseData.message || 'Banner image uploaded successfully!',
          reload: true
        });
        toast.success(responseData.message || 'Banner image uploaded successfully!')
        fetchVideo();
      } else {
        setMessage({ error: true, msg: 'error occured' });
        toast.error(responseData.message || 'error occured!')
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    }
  }
  // console.log(images)
  
  const initialValues = {
    banner_img: '',
    status: '',
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // onReload(true)
  };

  const handleSubmit = (values) => {
    images.length>0 && uploadHandler(values)
  };


  return (
    <div>
      {message?.msg && (
        <Alert severity={message?.error ? 'error' : 'success'} dismissible={'true'} onClose={() => setMessage('')}>
          {message?.msg}
        </Alert>
      )}
      <Button variant="outlined" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        New Banner Image
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth={true}>
        <DialogTitle>Add New Banner Image</DialogTitle>
        <DialogContent>
          <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={async (values) => {
            // console.log(values)
            await handleSubmit(values);
          }}
        >
          {({
            //@ts-ignore
            errors,
            values,
            //@ts-ignore
            touched,
          }) => (
            <Form>
            <Box p={3}>
              <Box width="100%" component="form" method="post" autoComplete="off">
                    <BannerImage onSelectFiles={(files)=> setImages(files)}/>    
                <Grid item xs={12} md={12}>
                  <DropDownBox
                    label="Status"
                    name="status"
                    options = {arrStatus}
                  />
                  <>{console.log(values)}</>
                </Grid>
              </Box>
            </Box>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button 
                type='submit'
              >Submit</Button>
            </DialogActions>
            </Form>
            )}
        </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddBanner;
