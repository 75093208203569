import { TextField } from '@mui/material';
import { useField, useFormik, useFormikContext } from 'formik';
import React from 'react';

function TextFieldBox(props) {
  const [field, meta] = useField(props);
  const { values, setFieldValue } = useFormikContext();
  // console.log(values)
  return (
    <div>
      <TextField
        label={props.label}
        type={props.type}
        name={props.name}
        value={props.value}
        disabled={props.disabled}
        onChange={(e) => setFieldValue(props.name, e)}
        variant="standard"
        // size="small"
        fullWidth
        {...field}
      />
    </div>
  );
}

export default TextFieldBox;
