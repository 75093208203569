import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
  Stack,
  Button,
  Container,
  Typography,
  Switch, 
  Box,
  Alert,
  ButtonGroup,
  CircularProgress,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
// Database
import { getFirestore } from 'firebase/firestore';
import { app } from '../firebaseConfig';
// components

import Iconify from '../components/iconify';
import baseUrl from '../utils/baseUrl';
import DataGrid from 'src/components/Datagrid/Datagrid';
import moment from 'moment/moment';
import Component from 'src/modules/advertise/UpdateAdvertiseEntity';
import PositionedSnackbar from 'src/components/Snackbar/Snackbar';
// ----------------------------------------------------------------------

const getMuiThemeLight = () =>
  createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: '#AAF',
        },
      },
    },
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: 10,
            minWidth: '30%',
          },
        },
      },
    },
  });
const INSERT_ADVERTISE_OBJ = {
  search_item: '',
  type_selection: '',
};
export default function AdvertiseentityPage() {
  const [open, setOpen] = useState(false);
  const [sucMsg, setSucMsg] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const [allFields, setAllFields] = useState([]);
  const [checked, setChecked] = useState({});
  const [allAddAdvertise, setAllAddAdvertise] = useState([]);
  const [allFilterArr, setAllFilterArr] = useState([]);
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    search_item: '',
    type_selection: 'status',
  });
  const [placeholderTxt, setPlaceholderTxt] = useState('Enter status here');
  const db = getFirestore(app);
  useEffect(() => {
    fetchVideo();
  }, [reload]);

  const fetchVideo = async () => {
    const arr = [
      { name: 'Search by id', value: 'id' },
      { name: 'Search by location', value: 'add_location_id' },
      { name: 'Search by userid', value: 'user_id' },
      { name: 'Search by listing', value: 'listing_id' },
      { name: 'Search by status', value: 'status' },
      { name: 'Search by weekly email', value: 'use_in_weekly_email' },
    ];
    setAllFilterArr(arr);
    const checkedObj = {};
    try {
      setLoading(true);
      const patchData = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      };
      const data = !inputs.search_item
        ? await fetch(`${baseUrl}/api/v1/advertisement/entity`)
        : await fetch(
            `${baseUrl}/api/v1/advertisement/entity?${inputs.type_selection}=${inputs.search_item}`,
            patchData
          );
      const arr = await data.json();
      const modifiedData = arr?.data?.totalAdvertise?.map((i) => {
        checkedObj[i?.id] = i?.active || false;
        return {
          ...i,
          useAsFilter: i.useAsFilter ? 'Yes' : 'No',
          category: i.listing_category?.name,
        };
      });
      const modifiedDataArr = modifiedData.sort((a, b) => a.id - b.id);
      setChecked(checkedObj);
      setAllFields([...modifiedDataArr]);
      setAllAddAdvertise([...modifiedDataArr]);
    } catch (error) {
      // console.log(error);
      setAllFields([]);
    } finally {
      setLoading(false);
    }

    // // console.log(checked);
  };

  const FilteredData = allFields?.map((field, key) => {
    return {
      Code: field.id,
      Id: field.id,
      'List Id': field.listing_id,
      'Type Of Add': field.type_of_add,
      Banner: '',
      'Location Id': field.add_location_id,
      'Page Name': field?.add_location?.page_name,
      'Location Name': field.add_location.location_name,
      'User Email': field.User.email,
      'User Name': field.User.name,
      'Start Time': moment(field.start_date_time).format('YYYY-MM-DD HH:mm'), 
      'End Time': moment(field.end_date_time).format('YYYY-MM-DD HH:mm'),
      'Desktop View': field.on_desktop_view ? 'Yes' : 'No',
      'Mobile View': field.on_mobile_view ? 'Yes' : 'No',
      'Weekly Email': field.use_in_weekly_email ? 'Yes' : 'No',
      Status: field.status
      // Pricing: field.pricing,
      // OpeningTime: field.openingTime,
      // ClosingTime: field.closingTime,
    }
  })

  const handleActiveDeleteChange = (event, itemId) => {
    // // console.log('handleActiveDeleteChange ----', itemId);
    const arr = allFields.filter((item) => item.id === itemId)[0];
    // // console.log('selected record:', arr);
    setSelectedItem(arr);
    setOpenAlert(true);
    setOpenAlertMsg({ message: 'Are you sure want to delete this Advertise', title: 'Delete this Advertise?' });
  };
  const handleAlertClose = () => {
    setOpenAlert(false);
    setOpenAlertMsg({ message: '', title: '' });
    setSelectedItem({});
  };
  const handleDeleteConfirm = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/advertisement/entity/${selectedItem?.id}`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        setMessage({ error: false, msg: 'Deleted Successfully!' });
        handleAlertClose();
        fetchVideo();
      } else {
        setMessage({ error: true, msg: 'error occured' });
      }
    } catch (error) {
      // console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    }
  };
  const handleStatuChange = async (e, selectedFieldItemNow) => {
    // console.log('handleStatuChange', e.target.checked, selectedFieldItemNow);
    const postObjData = {
      add_location_id: selectedFieldItemNow.add_location_id,
      listing_id: selectedFieldItemNow.listing_id,
      type_of_add: selectedFieldItemNow.type_of_add,
      add_entity: selectedFieldItemNow.add_entity,
      start_date_time: selectedFieldItemNow.start_date_time,
      end_date_time: selectedFieldItemNow.end_date_time,
      use_in_weekly_email: selectedFieldItemNow.use_in_weekly_email,
      on_desktop_view: selectedFieldItemNow.on_desktop_view,
      on_mobile_view: selectedFieldItemNow.on_mobile_view,
      status: e.target.checked ? 'active' : 'deactive',
    };
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postObjData),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/advertisement/entity/${selectedFieldItemNow.id}`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        setMessage({ error: false, msg: 'Status updated Successfully!' });
        handleClose();
        fetchVideo();
      } else {
        setMessage({ error: true, msg: 'error occured' });
      }
    } catch (error) {
      // console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    }
  };
  const fieldColumns = [
    { name: 'id', label: 'ID', options: { filter: false, sort: false, sortThirdClickReset: true, display: false } },
    {
      name: 'add_entity',
      label: 'Banner',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const arr = allAddAdvertise.filter((item) => item.id === tableMeta.rowData[0])[0];
          // console.log('selected record:', arr);
          return (
            <img
              src={arr.add_entity}
              style={{ maxWidth: '80px', width: '80px', height: '60px', objectFit: 'contain' }}
              alt="banner"
            />
          );
        },
      },
    },
    {
      name: 'location_id',
      label: 'Location Id',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const arr = allAddAdvertise.filter((item) => item.id === tableMeta.rowData[0])[0];
          // console.log('selected record:', arr);
          return <>{arr.add_location.id}</>;
        },
      },
    },
    {
      name: 'add_location.page_name',
      label: 'Page Name',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
        display: true,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          const arr = allAddAdvertise.filter((item) => item.id === tableMeta.rowData[0])[0];
          // console.log('selected record:', arr);
          return <>{arr?.add_location?.page_name}</>;
        },
      },
    },
    {
      name: 'add_location.location_name',
      label: 'Location Name',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
        enableNestedDataAccess: ".",
        customBodyRender: (value, tableMeta, updateValue) => {
          const arr = allAddAdvertise.filter((item) => item.id === tableMeta.rowData[0])[0];
          // console.log('selected record:', arr);
          return <>{arr.add_location.location_name}</>;
        },
      },
    },
    // {
    //   name: 'user_id',
    //   label: 'User Id',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     sortThirdClickReset: true,
    //     display: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const arr = allAddAdvertise.filter((item) => item.id === tableMeta.rowData[0])[0];
    //       // console.log('selected record:', arr);
    //       return <>{arr.User.id}</>;
    //     },
    //   },
    // },
    {
      name: 'User.email',
      label: 'User Email',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const arr = allAddAdvertise.filter((item) => item.id === tableMeta.rowData[0])[0];
          // console.log('selected record:', arr);
          return <>{arr.User.email}</>;
        },
      },
    },
    {
      name: 'User.name',
      label: 'User Name',
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const arr = allAddAdvertise.filter((item) => item.id === tableMeta.rowData[0])[0];
          //  // console.log('selected record:', arr);
          return <>{arr.User.name}</>;
        },
      },
    },

    {
      name: 'start_date_time',
      label: 'Start Time',
      options: { filter: true, sort: false, sortThirdClickReset: true },
    },
    { name: 'end_date_time', label: 'End Time', options: { filter: true, sort: false, sortThirdClickReset: true } },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // // console.log(value, tableMeta, updateValue)
          const arr = allAddAdvertise.filter((item) => item.id === tableMeta.rowData[0])[0];
          // console.log('selected record:', arr,);
          // arr.status === 'active' ? true : false
          // // console.log('getiing arr:', arr.options);
          return (
            <Switch
              checked={arr.status === 'active'}
              onChange={(e) => handleStatuChange(e, arr)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          );
        },
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // // console.log(value, tableMeta, updateValue)
          const arr = allAddAdvertise.filter((item) => item.id === tableMeta.rowData[0])[0];
          // // console.log('selected record:', arr);
          // // console.log('getiing arr:', arr.options);
          return (
            <ButtonGroup size="small" variant="outlined" aria-label="small outlined primary button group">
              {/* <UpdatedAdvertise fetchVideo={fetchVideo} startIcon={<Iconify icon="ic:baseline-edit" />}  selectedFieldItem={arr} /> */}
              <Button
                variant="outlined"
                color="error"
                startIcon={<Iconify icon="ph:trash-bold" />}
                onClick={(event) => handleActiveDeleteChange(event, tableMeta.rowData[0])}
              />
            </ButtonGroup>
          );
        },
      },
    },
  ];
  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (event.target.value === 'id') {
      await setPlaceholderTxt('Enter id here');
    } else if (event.target.value === 'add_location_id') {
      await setPlaceholderTxt('Enter add location id');
    } else if (event.target.value === 'user_id') {
      await setPlaceholderTxt('Enter user id');
    } else if (event.target.value === 'listing_id') {
      await setPlaceholderTxt('Enter listing id');
    } else if (event.target.value === 'status') {
      await setPlaceholderTxt('Enter status here');
    } else {
      await setPlaceholderTxt('Enter weekly email status');
    }
    await setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  // **************[options UseState]*****************/
  const [optionsTxt, setOptionsTxt] = useState('');
  const [options, setOptions] = useState([]);
  const [showOptionField, setShowOptionField] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [message, setMessage] = useState({ error: false, msg: '' });
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertMsg, setOpenAlertMsg] = useState({ message: '', title: '' });

  const handleClose = () => {
    setOpen(false);
    setOpenAlert(false);
  };

  const updateOptions = (e, value, tableMeta, updateValue) => {
    // // console.log('changeUserStatus ---------------: ', tableMeta.rowData);
    const arr = allFields.filter((item) => item.id === tableMeta.rowData[0])[0];
    // // console.log('selected record:', arr);
    setOpen(true);
    // // console.log('getiing arr:', arr.options, open);
    setOptionsTxt('');
    setOptions(arr.options);
    setShowOptionField(true);
    setSelectedItem(arr);
  };
  const handlerDialogOnClose = () => {
    setOpen(null);
    setOptionsTxt('');
    setOptions([]);
    setShowOptionField(false);
    setSelectedItem({});
  };
  const onClickEvent = ()=>{

  }
  const snackBarHandler = (data)=>{
    console.log(data)
    setOpen(data.open);
    setErrMsg(data.errMsg);
    setSucMsg(data.sucMsg);
    setReload(data.reload);
  }
  const reloadHandler = (data)=>{
    setReload(data)
  }

  return (
    <>
      <Helmet>
        <title> Advertise Entities </title>
      </Helmet>
      {/* **************dialog****************** */}
      {message?.msg && (
        <Alert severity={message?.error ? 'error' : 'success'} dismissible={'true'} onClose={() => setMessage('')}>
          {message?.msg}
        </Alert>
      )}
      <Container>
        {/* <PositionedSnackbar
          open={open}
          message_err={errMsg}
          message_suc={sucMsg}
          handleClose={handleClose}
        /> */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            All Advertisements
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}> */}
          {/* <Button variant="outlined" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add New Field        
          </Button> */}
          {/* <AddAdvertiseEntity onReload={reloadHandler} /> */}
        </Stack>

        {FilteredData?.length > 0 ? <DataGrid
            data={FilteredData}
            onClickEvent={onClickEvent}
            checkBox
            pagingTool
            hideTool
            isFilter
            empGrid
            wrap
            name="/api/v1/advertisement/entity/"
            openSnackBar = {snackBarHandler}
            // fetchVideo={fetchVideo}
            renderComponent = {Component}
            reload = {(data)=> setReload(data)}
        /> : loading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}><CircularProgress /> &nbsp;Loading....</Box>
        : <h1 className="text-base text-center font-semibold">Data Not Found!!</h1>}
        {/* ===============[ Alert Dialogue Box ]=================== */}
      </Container>
    </>
  );
}
