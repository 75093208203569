/* eslint-disable import/no-unresolved */
import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { getFirestore } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import DataGrid from 'src/components/Datagrid/Datagrid'
import PositionedSnackbar from 'src/components/Snackbar/Snackbar';
import { app } from 'src/firebaseConfig';
import AddCityPrice from 'src/modules/cityprice/AddCityPrice';
import Component from 'src/modules/cityprice/UpdateCityPrice';
import baseUrl from 'src/utils/baseUrl';
import swal from 'sweetalert';

export default function CityPrice() {
    const [open, setOpen] = useState(false);
    const [sucMsg, setSucMsg] = useState("")
    const [errMsg, setErrMsg] = useState("")
    const [allFields, setAllFields] = useState([]);
    const [checked, setChecked] = useState({});
    const [reload,setReload] = useState(false)
    const [loading,setLoading] = useState(true)

    const db = getFirestore(app);

    useEffect(() => {
        fetchPrice();
    }, [reload]);


    const fetchPrice = async () => {
        const url = `${baseUrl}/api/v1/advertisement/city_price`;
        const config = {
            method: 'get',
            url: `${url}`,
            headers: {
                'Authorization': JSON.parse(localStorage.getItem('token')),
            }
        };

        axios.request(config)
            .then(async (response) => {
                console.log(response);
                if(response.status === 200) {
                    setAllFields(response.data?.data?.listOfAddCity);
                    setLoading(false)
                } else if(response.status === 401 || response.status === 403 || response.status === 440) {
                    // await logout();
                } else if (response.status === 500) {
                    swal('error', 'Server Error !', 'error');
                }
            })
            .catch((error) => {
                console.log(error);
            });
      
        // console.log(modifiedArr);
    };
    const FilteredData = allFields?.map((field, key) => {
        return {
          Code: field.id,
          Id: field.id,
          'Country': field.country_id,
          'City Name': field.city_name,
          Price: field.price,
        //   Status: field.status
        }
      })
    const reloadHandler = (data)=>{
        setReload(data);
    }


    const onClickEvent = () => {

    }
    const snackBarHandler = (data)=>{
      console.log(data)
      setOpen(data.open);
      setErrMsg(data.errMsg);
      setSucMsg(data.sucMsg);
      // setReload(data.reload);
      data.reload && fetchPrice()
    }
    const handleClose = ()=>{
      setOpen(!open);
    }
    return (
        <div>
      <Helmet>
        <title> City Price </title>
      </Helmet>
      <PositionedSnackbar
          open={open}
          message_err={errMsg}
          message_suc={sucMsg}
          handleClose={handleClose}
        />

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            City Price
          </Typography>
          <AddCityPrice onSnackBar={snackBarHandler}/>
        </Stack>

        {FilteredData?.length> 0 ? <DataGrid
            data={FilteredData}
            onClickEvent={onClickEvent}
            checkBox
            pagingTool
            hideTool
            isFilter
            empGrid
            wrap
            // name="/api/v1/header_menu/"
            // componentName="editExpenceForm"
            // viewName="viewExpenseForm"
            openSnackBar = {snackBarHandler}
            // fetchVideo={fetchVideo}
            renderComponent = {Component}
            // reload = {reloadHandler}
        /> : loading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}><CircularProgress /> &nbsp;Loading....</Box>
        : <h1 className="text-base text-center font-semibold">Data Not Found!!</h1>}
      </Container>
    </div>
    )
}
