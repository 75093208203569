import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { Form, Formik } from 'formik';
import baseUrl from 'src/utils/baseUrl';
import TextFieldBox from '../custom_fields/TextFieldBox';
import { toast } from 'react-hot-toast';

export default function UpdateCityPrice(props,onCancel,onSnackBar) {
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [coutryList,setCountryList] = React.useState([])
    const [selectedCountry, setSelectedCountry] = React.useState("")
    const [stateList,setStateList] = React.useState([])

    const getCountryList = async () => {
        try {
          const data = await fetch(
            `${baseUrl}/api/v1/list/country`,
    
          );
          const arr = await data.json();
          setCountryList(arr.data);
        } catch (error) {
          console.log(error);
        }
    };
    const getStateList = async (country) => {
        try {
          const data = await fetch(
            `${baseUrl}/api/v1/list/country?country_id=${country}`,
          );
          const arr = await data.json();
          let state = [];
          arr.data.forEach((value) => {
            state.push({name: value.name})
          })
          setStateList(state);
    
        } catch (error) {
          console.log(error);
        }
      };
    React.useEffect(()=> {
        getCountryList()
    },[])
    React.useEffect(()=>{
        selectedCountry > 0 && getStateList(selectedCountry)
    },[selectedCountry])


    const handleClose = () => {
    onCancel(true)
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            event.target.value,
        );
    };

    const handleSubmit = async (values) => {
        const requestOptions = {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          },
          body: JSON.stringify((({ country_id,city_name, ...o }) => o)(values)),
        };
        try {
          const data = await fetch(`${baseUrl}/api/v1/advertisement/city_price/${props['City Name']}`, requestOptions);
          const responseData = await data.json();
          if (responseData?.errors?.length === 0) {
            handleClose();
            onSnackBar({
              open: true,
              errMsg: "",
              sucMsg: responseData.message || 'City price updated successfully!',
              reload: true
            });
            toast.success(responseData.message || 'City price updated successfully!')
          } else {
            toast.error(responseData.message || 'Error occurred while updating!')
          }
        } catch (error) {
          console.log(error);
        }
      };

    return (
        <React.Fragment>
                <DialogTitle>Update City Price</DialogTitle>
                <Formik
          initialValues={{
            // country_id: props['Country'],
            // city_name: props['City Name'],
            price: props['Price'],
        //   // length: props.Length,
        //   length: props.Length,
        //   height: props.Height,
        //   status: props.Status,
        //   price: props.Price
        }}
          // validationSchema={validationSchema}
          onSubmit={async (values) => {
            // console.log(values)
            await handleSubmit(values);
          }}
        >
          {({
            //@ts-ignore
            errors,
            values,
            //@ts-ignore
            touched,
          }) => (
            <Form>
                <DialogContent>
                    <DialogContentText>
                        {/* You can set my maximum width and whether to adapt or not. */}
                    </DialogContentText>
                    <Box
                        noValidate
                        component="form"
                        className='grid sm:grid-cols-2 gap-3'
                    >
                        {/* <FormControl sx={{ mt: 2 }}>
                            <DropDownBox
                                name="country_id"
                                label="Country"
                                options={coutryList}
                                disabled={true}
                            />
                            {setSelectedCountry(values.country_id)}
                            <>
                              {console.log(values)}
                            </>
                        </FormControl>
                        <FormControl sx={{ mt: 2 }}>
                            <DropDownBox
                                name="city_name"
                                label="State"
                                options={stateList}
                                disabled={true}
                            />
                        </FormControl> */}
                        <FormControl>
                          <TextFieldBox
                              label="City Price"
                              type="number"
                              name="price"
                          />
                        </FormControl>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button 
                        type='submit'
                        variant='outlined'
                    // onClick={handleSubmit}
                    >Submit</Button>
                </DialogActions>
                </Form>
            )}
        </Formik>
        </React.Fragment>
    );
}