import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import {
  Box,
  Alert,
  Grid,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { getFirestore } from 'firebase/firestore';
import { app } from '../../firebaseConfig';
import baseUrl from '../../utils/baseUrl';
import { Form, Formik } from 'formik';
import TextFieldBox from '../custom_fields/TextFieldBox';
import DropDownBox from '../custom_fields/DropDownBox';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';




const UpdateAdvertise = (props, onCancel, onSnackBar) => {
  const [coun, setCoun] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusAllArr, setStatus] = useState([]);
  const [inputs, setInputs] = useState({
    page_name: props['Page Name'],
    location_name: props['Location Name'],
    location_info: '',
    length: props.Length,
    width: props.length,
    height: props.Height,
    status: props.Status,
  });
  const [selectedFieldItemNow, setSelectedFieldItem] = useState({});
  const [message, setMessage] = useState({ error: false, msg: '' });


  const [options, setOptions] = useState([]);
  const [showOptionField, setShowOptionField] = useState(false);

  const db = getFirestore(app);

  const fetchSubs = async () => {

  };
  const fetchCoun = async () => {
    const arr = [
      { value: 1, name: 'Home' },
    ];
    const arrStatus = [
      {  name: 'active' },
      // { name: 'pending' },
      // {  name: 'deactive' },
    ];

    console.log("props.selectedFieldItem", props)
    setCoun(arr);
    setStatus(arrStatus)
    setSelectedFieldItem((old) => { return { ...old, ...props.selectedFieldItem } });
    setInputs({
      page_name: props['Page Name'],
      location_name: props['Location Name'],
      location_info: props.location_info,
      length: props.Length,
      width: props.length,
      height: props.Height,
      status: props.Status,
    })
  };

  useEffect(() => {
    fetchCoun();
    fetchSubs();
    // fetchTrans();
  }, []);
  useEffect(() => {
    if (['selectBox', 'checkboxMulti', 'radio'].includes(inputs.type_of_field)) {
      setOptions([]);
      setShowOptionField(true);
    } else {
      setOptions([]);
      setShowOptionField(false);
    }
  }, [inputs]);


  const handleClose = () => {
    // setOpen(false);
    onCancel(true)
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/advertisement/location/${props.Id}`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        handleClose();
        onSnackBar({
          open: true,
          errMsg: "",
          sucMsg: responseData.message || 'Price updated successfully!',
          reload: true
        });
        toast.success(responseData.message || 'Price updated successfully!')
        // fetchVideo();
      } else {
        // setMessage({ error: true, msg: 'error occured' });
        toast.error(responseData.message || 'error occured!')
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {message?.msg && (
        <Alert severity={message?.error ? 'error' : 'success'} dismissible={'true'} onClose={() => setMessage('')}>
          {message?.msg}
        </Alert>
      )}

      <DialogTitle>Update advertise</DialogTitle>
      <div>

        <Box>
          <Formik
            initialValues={{
              page_name: props['Page Name'],
              location_name: props['Location Name'],
              location_info: props['Location Info'],
              // length: props.Length,
              length: props.Length,
              height: props.Height,
              status: props.Status,
              price: props.Price
            }}
            // validationSchema={validationSchema}
            onSubmit={async (values) => {
              // console.log(values)
              await handleSubmit(values);
            }}
          >
            {({
              //@ts-ignore
              errors,
              values,
              //@ts-ignore
              touched,
            }) => (
              <Form>
                <Box p={3}>
                  <Box width="100%" component="form" method="post" autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>

                        <DropDownBox
                          label="Page name"
                          name="page_name"
                          options={coun}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>

                        <TextFieldBox
                          label="Advertise Location"
                          type="text"
                          name="location_name"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>

                        <TextFieldBox
                          label="Advertise Info"
                          type="text"
                          name="location_info"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>

                        <TextFieldBox
                          label="Price"
                          type="number"
                          name="price"
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <span><b>Area Size</b></span>
                      </Grid>
                      <Grid item xs={12} md={6}>

                        <TextFieldBox
                          label="Advertise Length"
                          type="text"
                          name="length"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>

                        <TextFieldBox
                          label="Advertise Height"
                          type="text"
                          name="height"
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>

                        <DropDownBox
                          label="Advertise Status"
                          name="status"
                          options={statusAllArr}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <LoadingButton
                    type='submit'
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    <span>Submit</span>
                  </LoadingButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Box>
      </div>
    </>
  );
};

export default UpdateAdvertise;
