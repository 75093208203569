import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { Form, Formik } from 'formik';
import baseUrl from 'src/utils/baseUrl';
import TextFieldBox from '../custom_fields/TextFieldBox';
import { toast } from 'react-hot-toast';
import Iconify from '../../components/iconify';

export default function AddCityPrice({ onSnackBar }) {
  const [open, setOpen] = React.useState(false);
  // const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  // const getCountryList = async () => {
  //   try {
  //     const data = await fetch(`${baseUrl}/api/v1/list/country`);
  //     const arr = await data.json();
  //     setCountryList(arr.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const getStateList = async (country) => {
  //   try {
  //     const data = await fetch(`${baseUrl}/api/v1/list/country?country_id=${country}`);
  //     const arr = await data.json();
  //     let state = [];
  //     arr.data.forEach((value) => {
  //       state.push({ name: value.name });
  //     });
  //     setStateList(state);
  //   } catch (error) {
  //     console.log(error);
  //     //   toast.error("Someting went wrong on data fetch, Please try again");
  //     //  setAllFields([]);
  //   }
  // };
  // React.useEffect(() => {
  //   getCountryList();
  // }, []);
  // React.useEffect(() => {
  //   selectedCountry > 0 && getStateList(selectedCountry);
  // }, [selectedCountry]);
  // console.log(typeof(selectedCountry))

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // onReload(true)
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleSubmit = async (values) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
      },
      // body: JSON.stringify((({ country, ...o }) => o)(values)),
      body: JSON.stringify({
        ...values,
        country_id: 1,
        city_name: 'bjb',
      }),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/advertisement/city_price`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        // setMessage({ error: false, msg: 'Price added successfully!' });
        handleClose();
        onSnackBar({
          open: true,
          errMsg: '',
          sucMsg: responseData.message || 'Price added successfully!',
          reload: true,
        });
        toast.success(responseData.message || 'Price added successfully!');
        // fetchVideo();
      } else {
        // setMessage({ error: true, msg: 'error occured' });
        toast.error(responseData.message || 'error occured');
      }
    } catch (error) {
      console.log(error);
      //   setMessage({ error: true, msg: 'error occured' });
    }
  };

  return (
    <React.Fragment>
      <Button variant="outlined" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Add City Price Rate
      </Button>
      <Dialog fullWidth="md" maxWidth={'sm'} open={open} onClose={handleClose}>
        <DialogTitle>Add City Price Rate</DialogTitle>
        <Formik
          initialValues={{
            //     page_name: props['Page Name'],
            //   location_name: props['Location Name'],
            //   location_info: props['Location Info'],
            //   // length: props.Length,
            //   length: props.Length,
            //   height: props.Height,
            //   status: props.Status,
            price: '',
            // country_id: 2,
          }}
          // validationSchema={validationSchema}
          onSubmit={async (values) => {
            // console.log(values)
            await handleSubmit(values);
          }}
        >
          {({
            //@ts-ignore
            errors,
            values,
            //@ts-ignore
            touched,
          }) => (
            <Form>
              <DialogContent>
                <DialogContentText>{/* You can set my maximum width and whether to adapt or not. */}</DialogContentText>
                <Box noValidate component="form" className="grid sm:grid-cols-2 gap-3">
                  {/* <FormControl sx={{ mt: 2 }}>
                            <DropDownBox
                                name="country_id"
                                label="Country"
                                options={coutryList}
                            />
                            {setSelectedCountry(values.country_id)}
                            <>
                              {console.log(values)}
                            </>
                        </FormControl>
                        <FormControl sx={{ mt: 2 }}>
                            <DropDownBox
                                name="city_name"
                                label="City"
                                options={stateList}
                            />
                        </FormControl> */}
                  <FormControl>
                    <TextFieldBox label="City Price" type="number" name="price" />
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button
                  type="submit"
                  variant="outlined"
                  // onClick={handleSubmit}
                >
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
