import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  TextField,
  MenuItem,
  Alert,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Stack,
  Chip,
} from '@mui/material';
import Iconify from '../../components/iconify';
import baseUrl from '../../utils/baseUrl';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

const AddSubCategories = ({ fetchSubs }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({ error: false, msg: '' });
  const [categories, setCategories] = useState([]);
  const [inputs, setInputs] = useState({ cat_id: null, subcatname: '' });
  const [loading, setLoading] = useState(false);
  const [optionsTxt, setOptionsTxt] = useState('');
  const [options, setOptions] = useState([]);

  const handleAddOption = () => {
    if (optionsTxt) {
      console.log('event------------->', optionsTxt, options);
      setOptions([...options, optionsTxt]);
      setOptionsTxt('');
    }
  };
  const handleAddOptionTxt = (event) => {
    console.log('event------------->', event.target.value);
    setOptionsTxt(event.target.value);
  };
  const handleDeleteOptions = (event) => {
    console.log('event------------->', event);
    const optionsList = options.filter((name) => name !== event);
    setOptions(optionsList);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const data = await fetch(`${baseUrl}/api/v1/categories`);
      const arr = await data.json();
      console.log(arr?.data);
      setCategories(arr?.data?.allCategories);
    } catch (error) {
      console.log(error);
      setCategories([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(JSON.parse(localStorage.getItem('token'))),
      },
      body: JSON.stringify({
        name: inputs.subcatname,
        categoryId: inputs?.cat_id,
        info: 'some info (optional)',
        keywords: options,
        // active: true,
      }),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/subCategories`, requestOptions);
      const resolvedData = await data.json();
      console.log(resolvedData);
      if (resolvedData?.errors?.length === 0) {
        // setMessage({ error: false, msg: 'Added Successfully!' });
        toast.success('Added successfully!');
        fetchSubs();
        setInputs({ cat_id: null, subcatname: '' });
        setOptions([]);
        setOpen(false);
        handleClose();
      } else {
        setMessage({ error: true, msg: 'error occured' });
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setInputs({ cat_id: null, subcatname: '' });
    setOptions([]);
    setOpen(false);
  };

  return (
    <>
      {message?.msg && (
        <Alert severity={message?.error ? 'error' : 'success'} dismissible={'true'} onClose={() => setMessage('')}>
          {message?.msg}
        </Alert>
      )}

      <Button variant="outlined" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        New Sub-category
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
        <DialogTitle>Add New Sub-category</DialogTitle>
        <DialogContent>
          <Box>
            <Box p={3}>
              <Box width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      select
                      id="cat_id"
                      // size="small"
                      variant="filled"
                      color="info"
                      fullWidth
                      label="Category"
                      name="cat_id"
                      value={inputs.cat_id || ''}
                      onChange={handleChange}
                      helperText="Select Category"
                    >
                      {categories?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      // autoFocus
                      margin="dense"
                      id="subcatname"
                      variant="filled"
                      color="info"
                      fullWidth
                      label="Sub Category Name"
                      type="text"
                      name="subcatname"
                      value={inputs.subcatname || ''}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      // autoFocus
                      // margin="dense"
                      id="optionsTxt"
                      label="Keywords"
                      type="text"
                      variant="filled"
                      color="info"
                      fullWidth
                      name="optionsTxt"
                      value={optionsTxt}
                      onChange={handleAddOptionTxt}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Button onClick={handleAddOption} variant="outlined">
                      Add Keywords
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Stack direction="row" spacing={2} flexWrap={'wrap'}>
                      {options.map((item, index) => (
                        <Chip
                          style={{
                            marginTop: 10,
                          }}
                          label={item}
                          variant="filled"
                          color="info"
                          fullWidth
                          onDelete={() => handleDeleteOptions(item)}
                          key={item}
                        />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={loading}
            onClick={handleSubmit}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
          >
            <span>Add SubCategory</span>
          </LoadingButton>
          {/* <Button onClick={handleSubmit} variant='outlined'>Submit</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddSubCategories;
