// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: icon('ic_user'),
  },
  {
    title: 'listings',
    path: '/dashboard/listings',
    icon: icon('ic_user'),
  },
  {
    title: 'categories',
    path: '/dashboard/categories',
    icon: icon('ic_blog'), // ic_cart
  },
  {
    title: 'sub-categories',
    path: '/dashboard/subcategories',
    icon: icon('ic_blog'),
  },
  {
    title: 'custom fields',
    path: '/dashboard/customfields',
    icon: icon('ic_lock'),
  },
  {
    title: 'Ad Placceholder',
    path: '/dashboard/advertise',
    icon: icon('ic_lock'),
  },
  {
    title: 'All Ads',
    path: '/dashboard/advertiseentity',
    icon: icon('ic_lock'),
  },
  {
    title: 'Groups',
    path: '/dashboard/groups',
    icon: icon('ic_lock'),
  },
  {
    title: 'Discussions',
    path: '/dashboard/discussion',
    icon: icon('ic_lock'),
  },
  {
    title: 'City Price',
    path: '/dashboard/cityprice',
    icon: icon('ic_lock'),
  },
  {
    title: 'Banner Images',
    path: '/dashboard/bannerimageupload',
    icon: icon('ic_lock'),
  },
  {
    title: 'Header Menu',
    path: '/dashboard/headermenu',
    icon: icon('ic_lock'),
  },
  // {
  //   title: 'countries',
  //   path: '/dashboard/countries',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'transcription',
  //   path: '/dashboard/transcription',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
