import { Helmet } from 'react-helmet-async';
// import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Switch, // Popover,MenuItem,Table,Paper,Avatar,IconButton,Checkbox,TableRow,TableBody,TableCell,TableContainer,TablePagination,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Chip,
  Box,
  Alert,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';

import AddField from '../modules/custom_fields/AddField';
import UpdatedCustomField from '../modules/custom_fields/UpdateCustomField';

import baseUrl from '../utils/baseUrl';
import toast from 'react-hot-toast';

const getMuiThemeLight = () =>
  createTheme({
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: '#AAF',
        },
      },
    },
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderStyle: 'solid',
            borderWidth: 2,
            borderRadius: 10,
            minWidth: '30%',
          },
        },
      },
    },
  });

export default function CustomFieldPage() {
  const [open, setOpen] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [checked, setChecked] = useState({});

  const fetchVideo = async () => {
    const checkedObj = {};
    try {
      const data = await fetch(`${baseUrl}/api/v1/category/customefield`);
      const arr = await data.json();
      const modifiedData = arr?.data?.customeFields.map((i) => {
        checkedObj[i?.id] = i?.active || false;
        return {
          ...i,
          useAsFilter: i.useAsFilter ? 'Yes' : 'No',
          category: i.listing_category ? i.listing_category?.name : i.SubCategory?.name,
        };
      });
      setChecked(checkedObj);
      setAllFields(modifiedData);
    } catch (error) {
      console.log(error);
      setAllFields([]);
    }

    // console.log(checked);
  };

  const fetchSubs = async () => {
    try {
      const data = await fetch(`${baseUrl}/api/v1/categories`);
      const arr = await data.json();
      // console.log(arr?.data);
      setAllCategory(arr?.data?.allCategories);
    } catch (error) {
      console.log(error);
      setAllCategory([]);
    }
  };

  useEffect(() => {
    fetchVideo();
    fetchSubs();
  }, []);
  // useEffect(() => {
  //   console.log(checked);
  // }, [checked]);

  const handleActiveSwitchChange = async (event, switchID) => {
    // console.log('handleActiveSwitchChange - status ----', event.target.checked, !checked[switchID], switchID);
    setChecked({ ...checked, [switchID]: !checked[switchID] });

    const patchData = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        active: !checked[switchID],
      }),
    };

    try {
      const data = fetch(`${baseUrl}/api/v1/category/customefield/${switchID}`, patchData);
      // const responseData = await data.json();
      if (data) {
        toast.success('CustomField updated successfully');
      }
    } catch (error) {
      console.log(error);
      toast.error('Error updating custom field');
    }
  };

  const fieldColumns = [
    { name: 'id', label: 'ID', options: { filter: false, sort: false, sortThirdClickReset: true, display: false } },
    { name: 'name', label: 'Name', options: { filter: false, sort: false, sortThirdClickReset: true, display: true } },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: false,
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const TYPE = value === 'selectBox' ? 'Select Dropdown ' : value;
          return (
            <div>
              <h5 style={{ textTransform: 'uppercase', color: '#888' }}>{TYPE}</h5>
              {value === 'selectBox' || value === 'radio' || value === 'checkboxMulti' ? (
                <Button
                  autoFocus
                  variant="outlined"
                  size="small"
                  onClick={(e) => updateOptions(e, value, tableMeta, updateValue)}
                >
                  Options
                </Button>
              ) : null}
            </div>
          );
        },
      },
    },
    {
      name: 'category',
      label: 'Category / Sub-Category',
      options: { filter: false, sort: false, sortThirdClickReset: true },
    },
    { name: 'useAsFilter', label: 'Use As Filter', options: { filter: false, sort: false, sortThirdClickReset: true } },
    {
      name: 'active',
      label: 'Active',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Switch
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary" // defaultChecked
            checked={checked[tableMeta.rowData[0]]}
            onChange={(event) => handleActiveSwitchChange(event, tableMeta.rowData[0])}
          />
        ),
      },
    },
    {
      name: 'actions',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(value, tableMeta, updateValue)
          const arr = allFields.filter((item) => item.id === tableMeta.rowData[0])[0];
          // console.log('selected record:', arr);
          // console.log('getiing arr:', arr.options);
          return (
            <UpdatedCustomField
              category={allCategory}
              fetchVideo={fetchVideo}
              selectedFieldItem={tableMeta.rowData}
              options={arr.options}
              cat_id={arr.categoryId}
            />
          );
        },
      },
    },
  ];

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  // **************[options UseState]*****************/
  const [optionsTxt, setOptionsTxt] = useState('');
  const [options, setOptions] = useState([]);
  const [showOptionField, setShowOptionField] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [message, setMessage] = useState({ error: false, msg: '' });
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertMsg, setOpenAlertMsg] = useState({ message: '', title: '' });

  const handleClose = () => {
    setOpen(false);
    setOpenAlert(false);
  };

  const handleAddOption = () => {
    if (optionsTxt) {
      console.log('event------------->', optionsTxt, options);
      setOptions([...options, optionsTxt]);
      setOptionsTxt('');
    }
  };
  const handleAddOptionTxt = (event) => {
    console.log('event------------->', event.target.value);
    setOptionsTxt(event.target.value);
  };
  const handleDeleteOptions = (event) => {
    console.log('event------------->', event);
    const optionsList = options.filter((name) => name !== event);
    setOptions(optionsList);
  };
  const updateOptions = (e, value, tableMeta, updateValue) => {
    // console.log('changeUserStatus ---------------: ', tableMeta.rowData);
    const arr = allFields.filter((item) => item.id === tableMeta.rowData[0])[0];
    // console.log('selected record:', arr);
    setOpen(true);
    // console.log('getiing arr:', arr.options, open);
    setOptionsTxt('');
    setOptions(arr.options);
    setShowOptionField(true);
    setSelectedItem(arr);
  };
  const handlerDialogOnClose = () => {
    setOpen(null);
    setOptionsTxt('');
    setOptions([]);
    setShowOptionField(false);
    setSelectedItem({});
  };
  const handleUpdate = async (event) => {
    console.log('selectedItem', selectedItem);
    const postObjData = {
      name: selectedItem?.name,
      categoryId: selectedItem?.categoryId,
      type: selectedItem?.type,
      fieldLength: '255',
      defaultValue: 'null',
      required: true,
      helpText: 'helpText',
      useAsFilter: true,
      active: true,
    };
    if (options.length > 0) {
      postObjData.options = options;
    }
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...postObjData }),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/category/customefield/${selectedItem?.id}`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        setMessage({ error: false, msg: 'Added Successfully!' });
        handleClose();
      } else {
        setMessage({ error: true, msg: 'error occured' });
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    }
  };
  const handleActiveDeleteChange = (event, itemId) => {
    console.log('handleActiveDeleteChange ----', itemId);
    const arr = allFields.filter((item) => item.id === itemId)[0];
    console.log('selected record:', arr);
    setSelectedItem(arr);
    setOpenAlert(true);
    setOpenAlertMsg({ message: 'Are you sure want to delete this field', title: 'Delete this field?' });
  };
  const handleAlertClose = () => {
    setOpenAlert(false);
    setOpenAlertMsg({ message: '', title: '' });
    setSelectedItem({});
  };
  const handleDeleteConfirm = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/category/customefield/${selectedItem?.id}`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        setMessage({ error: false, msg: 'Deleted Successfully!' });
        handleAlertClose();
        fetchVideo();
      } else {
        setMessage({ error: true, msg: 'error occured' });
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    }
  };
  return (
    <>
      <Helmet>
        <title> Custom Fields </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Custom Fields
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}> */}
          {/* <Button variant="outlined" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add New Field        
          </Button> */}
          <AddField fetchVideo={fetchVideo} />
        </Stack>

        <Card>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={getMuiThemeLight()}>
              <ThemeProvider theme={getMuiThemeLight()}>
                <MUIDataTable
                  title={'All Fields'}
                  data={allFields}
                  columns={fieldColumns}
                  options={{
                    responsive: 'standard',
                    print: false,
                    search: true,
                    viewColumns: false,
                    download: false,
                    pagination: true,
                    selectableRows: 'none',
                    filter: true,
                    filterType: 'dropdown', // 'checkbox',//
                    rowsPerPage: 20,
                    rowsPerPageOptions: [20],
                  }}
                />
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </Card>

        {/* **************dialog****************** */}
        {message?.msg && (
          <Alert severity={message?.error ? 'error' : 'success'} dismissible={'true'} onClose={() => setMessage('')}>
            {message?.msg}
          </Alert>
        )}
        <Dialog open={open} onClose={handlerDialogOnClose}>
          <DialogTitle>Options List</DialogTitle>
          <DialogContent>
            <Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Stack direction="row" spacing={1}>
                      {options.map((item, index) => (
                        <Chip label={item} variant="outlined" key={item} />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {/* <Button onClick={handleUpdate}>Update</Button> */}
          </DialogActions>
        </Dialog>

        {/* ===============[ Alert Dialogue Box ]=================== */}
        {/* <Dialog
          open={openAlert}
          onClose={handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{openAlertMsg.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{openAlertMsg.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAlertClose}>Cancel</Button>
            <Button onClick={handleDeleteConfirm} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog> */}
        {/* ===============[ Alert Dialogue Box ]=================== */}
      </Container>
    </>
  );
}
