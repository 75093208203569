import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import {
  Box,
  Alert,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
} from '@mui/material';
import Iconify from '../../components/iconify';
import baseUrl from '../../utils/baseUrl';
import { Form, Formik } from 'formik';
import TextFieldBox from '../custom_fields/TextFieldBox';
import { toast } from 'react-hot-toast';

const AddHeader = ({ allFields, fetchVideo, onSnackBar }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({ error: false, msg: '' });
  const [categories, setCategories] = useState([]);

  const initialValues = {
    categoryId: null,
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (values) => {
    const filtercategory = categories.find((item) => item.id === values.categoryId);

    const postData = {
      name: filtercategory.name,
      categoryId: filtercategory.id,
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
      },
      body: JSON.stringify(postData),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/header_menu`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        handleClose();
        onSnackBar({
          open: true,
          errMsg: '',
          sucMsg: responseData.message || 'Header created successfully!',
          reload: true,
        });
        toast.success(responseData.message || 'Header created successfully');
        // fetchVideo();
      } else {
        // setMessage({ error: true, msg: 'error occured' });
        toast.error(responseData.message || 'Error occured');
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    }
  };
  const fetchCategories = async () => {
    try {
      const data = await fetch(`${baseUrl}/api/v1/categories`);
      const arr = await data.json();
      // console.log(arr?.data);
      setCategories(arr?.data?.allCategories);
    } catch (error) {
      // console.log(error);
      setCategories([]);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div>
      {message?.msg && (
        <Alert severity={message?.error ? 'error' : 'success'} dismissible={'true'} onClose={() => setMessage('')}>
          {message?.msg}
        </Alert>
      )}
      <Button variant="outlined" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Add Header
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Header</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={async (values) => {
              // console.log(values)
              await handleSubmit(values);
            }}
          >
            {({
              //@ts-ignore
              errors,
              values,
              handleChange,
              //@ts-ignore
              touched,
            }) => (
              <Form>
                <Box p={3}>
                  <Box width="100%" component="form" method="post" autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          select
                          // id="cat_id"
                          // size="small"
                          variant="filled"
                          color="info"
                          fullWidth
                          label="Header Name"
                          name="name"
                          value={values.categoryId}
                          onChange={handleChange('categoryId')}
                          helperText="Select Header Name"
                        >
                          {categories?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {/* <Grid item xs={12} md={6}>
                    <TextFieldBox
                      label="Header Name"
                      type="text"
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextFieldBox
                      label="Header Path"
                      type="text"
                      name="base_href"
                    />
                  </Grid> */}
                    </Grid>
                  </Box>
                </Box>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddHeader;
