import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import {
  MenuItem,
  Box,
  TextField,
  Alert,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent} from '@mui/material';
import Iconify from '../../components/iconify';
import baseUrl from '../../utils/baseUrl';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-hot-toast';



const AddAdvertise = ({ fetchVideo,onSnackBar }) => {
  const [open, setOpen] = useState(false);
  const [coun, setCoun] = useState([]);
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    page_name: '',
    location_name: '',
    location_info: '',
    length: '',
    width: '',
    height: '',
    price: '',
    status: 'active',
  });

  const [message, setMessage] = useState({ error: false, msg: '' });


  const fetchCoun = async () => {
    const arr = [
      { id: 1, name: 'Home' },
    ];
    setCoun(arr);
  };

  useEffect(() => {
    fetchCoun();
    // fetchSubs();
  }, []);

  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    await setInputs((values) => ({ ...values, [name]: value }));
    console.log('input', inputs, name, value);

  };



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    console.log(inputs);
    if(!inputs.page_name || !inputs.location_name || !inputs.width || !inputs.height){
        setMessage({ error: true, msg: 'Please fill all the required fields' });
        setLoading(false);
        return;
    }
    const postObjData = {
      page_name: inputs.page_name,
      location_name: inputs.location_name,
      location_info: inputs.location_info,
      length: inputs.width,
      height: inputs.height,
      price: inputs.price,
      status: 'active',
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postObjData),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/advertisement/location`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        // setMessage({ error: false, msg: 'Added Successfully!' });
        setInputs({
          page_name: '',
          location_name: '',
          location_info: '',
          length: '',
          width: '',
          height: '',
          price: '',
          status: 'active',
        })
        handleClose();
        // fetchVideo();
        onSnackBar({
          open: true,
          errMsg: "",
          sucMsg: responseData.message || 'Added Successfully!!',
          reload: true
        });
        toast.success(responseData.message || 'Added Successfully!')
      } else {
        setMessage({ error: true, msg: 'error occured' });
        toast.error(responseData.message || 'error occured!')
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    } finally {
      setLoading(false);
    }
    
  };


  return (
    <div>
      {message?.msg && (
        <Alert severity={message?.error ? 'error' : 'success'} dismissible={'true'} onClose={() => setMessage('')}>
          {message?.msg}
        </Alert>
      )}
      <Button variant="outlined" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        New Placceholder
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Placceholder</DialogTitle>
        <DialogContent>
          <Box>
            <Box p={3}>
              <Box width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                     select
                     fullWidth
                      size="small"
                      label="Page name"
                      variant="standard"
                      name="page_name"
                      value={inputs.page_name || ''}
                      onChange={handleChange}
                      required
                    >
                    
                    {coun.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Advertise Location"
                      type="text"
                      name="location_name"
                      value={inputs.location_name || ''}
                      onChange={handleChange}
                      variant="standard"
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="text"
                      size="small"
                      label="Advertise Info"
                      variant="standard"
                      name="location_info"
                      value={inputs.location_info || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="number"
                      size="small"
                      label="Price"
                      variant="standard"
                      name="price"
                      value={inputs.price || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <span><b>Area Size</b></span>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      // autoFocus
                      //   margin="dense"
                      id="url"
                      label="Advertise Width"
                      type="text"
                      name="width"
                      value={inputs.width || ''}
                      onChange={handleChange}
                      fullWidth
                      variant="standard"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      // autoFocus
                      //   margin="dense"
                      id="url"
                      label="Advertise Height"
                      type="text"
                      name="height"
                      value={inputs.height || ''}
                      onChange={handleChange}
                      fullWidth
                      variant="standard"
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            // color="secondary"
            onClick={handleSubmit}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
          >
            <span>Submit</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddAdvertise;
