import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useLocation } from "react-router-dom";
import { Inactive, deleteIcon } from "src/assets";
import baseUrl from "src/utils/baseUrl";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { toast } from "react-hot-toast";
import swal from "sweetalert";

const Delete = (props) => {
    const [open,setOpen] = useState(false)
    const {pathname} = useLocation()
    const rejectApi = `${baseUrl}${props?.name}${props?.args.Code}`;
    const deleteHandler = async () => {
        swal({
            title: 'Are you sure?',
            text: 'Are you sure that you want to Delete?',
            icon: 'warning',
            dangerMode: true,
        }).then(async(willDelete) =>{
            if(willDelete){
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                // myHeaders.append("Access-Control-Allow-Origin", "*");
                myHeaders.append("Authorization", JSON.parse(localStorage.getItem("token")));
                try{
                await fetch(rejectApi, {
                    method: "DELETE",
                    headers: myHeaders,
                })
                    .then(async (response) => {
                        return Promise.all([response.status, response.json()]);
                    })
                    .then(([status,data]) => {
                    console.log(data);
                    if (status === 200 || status === 201) {
                        handleClose()
                        props.onDelete({
                        open: true,
                        errMsg: "",
                        sucMsg: data?.data?.msg 
                        // || 'Data deleted successfully!'
                        });
                        toast.success(data?.data?.msg || 'Data deleted successfully!');
                        setTimeout(() => {
                        props.onDelete({ reload: true });
                        }, 1000);
                    } else {
                        props.onDelete({
                        open: true,
                        sucMsg: "",
                        errMsg: data?.data?.msg,
                        reload: true,
                        });
                        toast.error(data?.data?.msg || 'Data deleted successfully!')
                    }
                    });
                }catch (err) {
                console.error(err);
                }
            }
        })
    };
    const BgblackTooltips = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        color: "#fff",
        boxShadow: theme.shadows[1],
        fontSize: 11,
        },
    }));
    const handleClose = ()=>{
        setOpen(!open)
    }
  return (
    <div>
        <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
                <Box width="100%" component="form" method="post" autoComplete="off">
                    {/* <Grid container spacing={2}> */}
                        {/* <Grid item xs={12} md={6}> */}
                            <p className="text-center font-semibold">Are you sure you want to delete this image?</p>
                        {/* </Grid> */}
                    {/* </Grid> */}
                </Box>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button 
                        type='button'
                        onClick={deleteHandler}
                    >Confirm</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
        <BgblackTooltips title={"Delete"} arrow>
        <img
            src={deleteIcon}
            width="17px"
            height="17px"
            style={{cursor: "pointer"}}
            alt=""
            // onClick={() => setOpen(true)}
            onClick={() => deleteHandler()}
        />
        </BgblackTooltips>
    </div>
  );
};
export default Delete;
