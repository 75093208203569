/* eslint-disable arrow-body-style */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { Stack, Container, Typography, Box, CircularProgress } from '@mui/material';
import DataGrid from '../components/Datagrid/Datagrid';
import baseUrl from '../utils/baseUrl';
import PositionedSnackbar from 'src/components/Snackbar/Snackbar';

export default function Listings() {
  const [open, setOpen] = useState(null);
  const [allFields, setAllFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sucMsg, setSucMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [reload, setReload] = useState(false);

  const fetchVideo = async () => {
    try {
      setLoading(true);
      const data = await fetch(`${baseUrl}/api/v1/listings?status=all`);

      const arr = await data.json();
      const FilteredData = arr.listings.map((field, key) => {
        return {
          Code: field?.id,
          Id: key + 1,
          'Listing Title': field.listingTitle,
          description: field.description,
          Image: field.gallery,
          Email: field.email,
          Phone: field.phone,
          Location: field.location,
          Address: field.address,
          Zipcode: field.zipcode,
          Status: field.status,
          keyword: field.keyword,
          categoryObject: field.listing_category,
          subCategoryObject: field.SubCategory,
          listing_customeField_data: field.listing_customeField_data[0].custome_data,
          userObject: field.User,
        };
      });

      setAllFields(FilteredData);
    } catch (error) {
      // console.log(error);
      setAllFields([]);
    } finally {
      setLoading(false);
    }
  };

  const onClickEvent = () => {};
  const snackBarHandler = (data) => {
    // console.log(data);
    setOpen(data.open);
    setErrMsg(data.errMsg);
    setSucMsg(data.sucMsg);
    setReload(data.reload);
  };
  const handleClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    fetchVideo();
  }, [reload]);

  return (
    <>
      <Helmet>
        <title> Listings </title>
      </Helmet>

      <Container>
        {/* <PositionedSnackbar
          open={open}
          message_err={errMsg}
          message_suc={sucMsg}
          handleClose={handleClose}
        /> */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Listings
          </Typography>
        </Stack>

        {allFields.length > 0 ? (
          <DataGrid
            data={allFields}
            onClickEvent={onClickEvent}
            checkBox
            pagingTool
            hideTool
            isFilter
            empGrid
            isView
            name="/api/v1/listings/"
            // componentName="editExpenceForm"
            // viewName="viewExpenseForm"
            openSnackBar={snackBarHandler}
          />
        ) : loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <CircularProgress /> &nbsp;Loading....
          </Box>
        ) : (
          <h1 className="text-base text-center font-semibold">Data Not Found!!</h1>
        )}
      </Container>
    </>
  );
}
