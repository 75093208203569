import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select from 'react-select';
import Button from '@mui/material/Button';
import { Box, Alert, Grid, Dialog, DialogTitle, DialogActions, CircularProgress } from '@mui/material';
import Iconify from '../../components/iconify';
import baseUrl from '../../utils/baseUrl';
import { Form, Formik } from 'formik';
import TextFieldBox from '../custom_fields/TextFieldBox';
import DropDownBox from '../custom_fields/DropDownBox';
import useCountry from 'src/utils/useCountry';
import useCategories from 'src/utils/useCategories';
import useTagList from 'src/utils/useTagList';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-hot-toast';
import { useAuth } from 'src/utils/services/auth/authService';

const AddGroups = ({ onSnackBar }) => {
  const [open, setOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [coun, setCoun] = useState([]);
  const [statusAllArr, setStatus] = useState([]);
  const [location, setLocation] = useState([]);
  const [image, setImage] = useState([]);
  const [selectedTag, setseletedTag] = useState([]);
  const tagInput = useRef(null);
  const [tagList, setTagList, loadingState] = useTagList(searchKey);
  const [inputs, setInputs] = useState({});
  const [selectedFieldItemNow, setSelectedFieldItem] = useState({});
  const [message, setMessage] = useState({ error: false, msg: '' });
  const [selectedState, setSelectedState] = useState({
    label: '',
    value: '',
  });
  const [options, setOptions] = useState([]);
  const [showOptionField, setShowOptionField] = useState(false);
  const auth = useAuth();

  const [countries] = useCountry(null);
  const [listCategories] = useCategories();

  const fetchCoun = async () => {
    const arr = [
      { value: 1, name: 'Home' },
      { value: 2, name: 'Listing' },
    ];
    // const arr = ['Home', 'Listing'];
    const arrStatus = [
      { id: 1, name: 'active' },
      { id: 2, name: 'pending' },
      { id: 3, name: 'deactive' },
    ];

    setCoun(arr);
    setStatus(arrStatus);
  };

  const handleAddTagInDb = async () => {
    try {
      setLoading(true);
      const url = `${baseUrl}/api/v1/disscussion-tags`;
      const payload = { tag: tagInput.current.value };

      const data = await fetch(url, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const resData = await data.json();
      if (resData.data) {
        setseletedTag((prev) => [...prev, resData.data]);
        tagInput.current.value = ''; //clear the form
      } else {
        // toast.error("Server error !");
      }
    } catch (err) {
      //   toast.error("Something went wrong", err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTagSelect = (tag) => {
    for (let i = 0; i < selectedTag.length; i++) {
      if (selectedTag[i].id == tag.id) {
        // toast.error("Tag selected already");
        return false;
      }
    }
    setseletedTag((prev) => [...prev, tag]);
    setTagList([]);
    tagInput.current.value = ''; //clear the form
  };

  const handleRemoveTag = (id) => {
    setseletedTag((previ) => previ.filter((item) => item.id !== id));
  };

  const changeHandler = async (event) => {
    await setSearchKey(event.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 800), []);
  // after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  /** *********************[useEffect]*********************** */
  useEffect(() => {
    fetchCoun();
    fetchVideo();
    // fetchTrans();
  }, []);
  useEffect(() => {
    if (['selectBox', 'checkboxMulti', 'radio'].includes(inputs.type_of_field)) {
      setOptions([]);
      setShowOptionField(true);
    } else {
      setOptions([]);
      setShowOptionField(false);
    }
  }, [inputs]);

  useEffect(() => {
    auth.getCityList(selectedState.value);
  }, [selectedState]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setseletedTag([]);
    setOpen(false);
    // props.onReload(true)
  };

  const fetchVideo = async () => {
    try {
      // setLoading(true);
      const data = await fetch(`${baseUrl}/api/v1/advertisement/location`);
      const arr = await data.json();
      const modifiedData = arr?.data?.map((i) => {
        return {
          ...i,
        };
      });
      const modifiedDataSort = modifiedData.sort((a, b) => a.id - b.id);
      setLocation(modifiedDataSort.map((i) => ({ name: i.location_name, id: i.id })));
      // await setAllAddAdvertise([...modifiedDataSort]);
    } catch (error) {}
  };
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const url = `${baseUrl}/api/v1/groups/room`;
      values.tags = selectedTag.map((tag) => (tag = tag.id));
      const data = await fetch(url, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
        },
        body: JSON.stringify(values),
      });
      const resData = await data.json();

      if (resData.data) {
        resData.data &&
          onSnackBar({
            open: true,
            errMsg: '',
            sucMsg: 'Group Added sucessfully',
            reload: true,
          });
        toast.success('Group Added sucessfully!');
        handleClose();
        // formik.resetForm();
        setseletedTag([]);
      } else {
        toast.error('Error occured!');
      }
    } catch (error) {
      if (error.message == 'unAuthorized') {
        onSnackBar({
          open: true,
          sucMsg: '',
          errMsg: 'Please log in before create an discussion',
        });
      } else {
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {message?.msg && (
        <Alert severity={message?.error ? 'error' : 'success'} dismissible={'true'} onClose={() => setMessage('')}>
          {message?.msg}
        </Alert>
      )}
      <Button variant="outlined" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Add Group
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Group</DialogTitle>
        <div>
          <Box>
            <Formik
              initialValues={{
                room_name: '',
                room_type: 'public',
                location: '',
                tags: selectedTag?.map((tag) => (tag = tag.id)), //only send ids
                categoryId: '',
              }}
              // validationSchema={validationSchema}
              onSubmit={async (values) => {
                // console.log(values)
                await handleSubmit(values);
              }}
            >
              {({
                //@ts-ignore
                errors,
                values,
                setFieldValue,
                //@ts-ignore
                touched,
              }) => (
                <Form>
                  <Box p={3}>
                    <Box width="100%" component="form" method="post" autoComplete="off">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextFieldBox
                            label="Group Name"
                            name="room_name"
                            //   options = {coun}
                            type="text"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <DropDownBox label="Group Type" name="room_type" options={[{ name: 'public' }]} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              // defaultValue={colourOptions[0]}
                              placeholder="Select State"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  background: '#fff',
                                  borderTop: 'none',
                                  borderRight: 'none',
                                  borderLeft: 'none',
                                  borderColor: '#cccccc',
                                  borderRadius: 'none',
                                  minHeight: '38px',
                                  height: '38px',
                                  boxShadow: state.isFocused ? null : null,
                                }),
                              }}
                              labelledBy="Select"
                              value={selectedState.value === '' ? null : selectedState}
                              onChange={(e) => setSelectedState(e)}
                              options={auth.stateList}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              // defaultValue={colourOptions[0]}
                              placeholder="Select City"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  background: '#fff',
                                  // borderColor: values[`location`] ? 'red' : '#cccccc',
                                  borderTop: 'none',
                                  borderRight: 'none',
                                  borderLeft: 'none',
                                  borderColor: '#cccccc',
                                  borderRadius: 'none',
                                  minHeight: '38px',
                                  height: '38px',
                                  boxShadow: state.isFocused ? null : null,
                                }),
                              }}
                              isSearchable={true}
                              onChange={(e) =>
                                setFieldValue('location', e.value + ',' + selectedState.value + ',' + 'USA')
                              }
                              labelledBy="Select"
                              options={auth.cityList}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <DropDownBox
                            label="Categories"
                            name="categoryId"
                            options={listCategories.map((category) => ({ id: category.id, name: category.name }))}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <div className="flex flex-col">
                            <label>Discussion Tags</label>
                            <div className="">
                              {selectedTag?.length > 0 && (
                                <div className="">
                                  {selectedTag.map((tag) => (
                                    <span key={tag.id} className="chip badge badge-pill badge-info border rounded px-1">
                                      {tag.tag}
                                      <span
                                        style={{
                                          padding: '0 0px 0 10px',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveTag(tag.id)}
                                      >
                                        <FontAwesomeIcon icon={faXmark} />
                                      </span>
                                    </span>
                                  ))}
                                </div>
                              )}
                              <input
                                type="text"
                                className="w-full border outline-none p-[0.65rem] rounded focus:bg-gray-50"
                                placeholder="Exp: Keyword1"
                                name="listingTitle"
                                autoComplete="off"
                                // onChange={(value) => {
                                //   handleChangeFormValue("tags", value);
                                // }}
                                // value={formik.values[`tags`]}
                                ref={tagInput}
                                onChange={debouncedChangeHandler}
                              />
                              {loadingState && (
                                <Box sx={{ display: 'flex' }}>
                                  <CircularProgress />
                                </Box>
                              )}
                              {tagList?.length > 0 && (
                                <ul className="border pl-2 rounded">
                                  {tagList.map((tag) => (
                                    <li key={tag.id} className="cursor-pointer">
                                      <span onClick={() => handleTagSelect(tag)}>{tag.tag}</span>
                                    </li>
                                  ))}
                                </ul>
                              )}
                              {tagList?.length == 0 && tagInput.current?.value && !loadingState && (
                                <Button onClick={handleAddTagInDb} className="btn btn-light mt-1">
                                  Click to Add
                                </Button>
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <DialogActions sx={{ padding: 3 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                      type="submit"
                      variant="outlined"
                      // onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Box>
        </div>
      </Dialog>
    </>
  );
};

export default AddGroups;
