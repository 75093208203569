import {useEffect, useState} from 'react'
import axios from "axios";
import baseUrl from './baseUrl';
// import { toast } from "react-hot-toast";
// import { ApiRoutes, ApiBaseUrl } from "../../utils/const";

const useCategories = () => {
  const [listCategories, setListCategories] = useState([]);

    useEffect(() => {
      const abortController = new AbortController();
      const fetchCategories = async () => {
        try {
          const { data } = await axios.get(`${baseUrl}/api/v1/categories?status=active`, {
            signal: abortController.signal,
          });
          setListCategories(data.data?.allCategories || []);
        } catch (e) {
        //   toast.error(
        //     "Server error ! something went wrong in Fetching Categories"
        //   );
        }
      };
      fetchCategories();

      return () => {
        abortController.abort();
      };
    }, []);
  
    return [listCategories];
}

export default useCategories;