import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import {
  MenuItem,
  Box,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Divider,
  Alert,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import * as yup from 'yup';

import Iconify from '../../components/iconify';
import baseUrl from '../../utils/baseUrl';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';

const AddField = ({ fetchVideo }) => {
  const [open, setOpen] = useState(false);
  const [subs, setSubs] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [fiteredSubCategory, setFiteredSubCategory] = useState([]);
  const [coun, setCoun] = useState([]);
  // const [trans, setTrans] = useState([]);
  // const [inputs, setInputs] = useState({ cat_id: null, subcat_id: null });
  const [addMoreInput, setAddMoreInput] = useState([{}]);
  const [checkedUseAsFilter, setCheckedUseAsFilter] = React.useState(false);
  const [message, setMessage] = useState({ error: false, msg: '' });

  // **************[options UseState]*****************/
  const [optionsTxt, setOptionsTxt] = useState('');
  const [options, setOptions] = useState([]);
  const [showOptionField, setShowOptionField] = useState(false);

  const [customFieldOptions, setCustomFieldOptions] = React.useState('global');
  const [validationObject, setValidationObject] = useState({
    fieldName: yup.string('Enter Field Name').required('Field Name is required'),
    fieldType: yup.string('Enter Field Type').required('Field Type is required'),
  });

  const validationSchema = yup.object(validationObject);

  const formik = useFormik({
    initialValues: {
      fieldName: '',
      fieldType: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      handleSubmit(values);
    },
  });

  const handleToggleButtonChange = (event, newAlignment) => {
    setCustomFieldOptions(newAlignment);
  };

  const fetchSubCategory = async () => {
    try {
      const data = await fetch(`${baseUrl}/api/v1/subCategories`);
      const arr = await data?.json();
      setSubCategory(arr?.data?.subCategories);
    } catch (error) {
      console.log(error);
      setSubCategory([]);
    }
  };

  const fetchSubs = async () => {
    // const arr = [];
    try {
      const data = await fetch(`${baseUrl}/api/v1/categories`);
      const arr = await data.json();
      console.log(arr?.data);
      setSubs(arr?.data?.allCategories);
    } catch (error) {
      console.log(error);
      setSubs([]);
    }
    // setSubs(arr);
  };
  const fetchCoun = async () => {
    const arr = [
      { id: 1, name: 'text' },
      { id: 2, name: 'file' },
      { id: 3, name: 'url' },
      { id: 4, name: 'number' },
      { id: 5, name: 'videoUrl' },
      // { id: 6, name: 'textarea' },
      // { id: 7, name: 'checkboxMulti' },
      { id: 8, name: 'selectBox' },
      { id: 9, name: 'singleSelectBox' },
      { id: 10, name: 'date' },
      { id: 11, name: 'dateTime' },
      // { id: 12, name: 'dateRange' },
    ];

    setCoun(arr);
  };

  const handleChange = async (event) => {
    const value = event.target.value;
    if (customFieldOptions === 'sub-categories') {
      const fiterdCategory = subCategory.filter((i) => i.categoryId === value);
      setFiteredSubCategory(fiterdCategory);
    }

    formik.setFieldValue('catId', value);
  };

  useEffect(() => {
    if (['selectBox', 'checkboxMulti', 'singleSelectBox'].includes(formik.values.fieldType)) {
      setOptions([]);
      setShowOptionField(true);
    } else {
      setOptions([]);
      setShowOptionField(false);
    }
  }, [formik.values.fieldType]);

  // **************[options UseState]*****************/
  const handleAddOption = () => {
    if (optionsTxt) {
      setOptions([...options, optionsTxt]);
      setOptionsTxt('');
    }
  };
  const handleAddOptionTxt = (event) => {
    setOptionsTxt(event.target.value);
  };
  const handleDeleteOptions = (event) => {
    const optionsList = options.filter((name) => name !== event);
    setOptions(optionsList);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setInputs({ cat_id: null, subcat_id: null });
    formik.resetForm();
    setAddMoreInput(['']);
    setCheckedUseAsFilter(false);
    setOpen(false);
  };

  const handleSubmit = async (inputs) => {
    // console.log(inputs);

    const postObjData = {
      name: inputs?.fieldName,
      type: inputs?.fieldType,
      // categoryId: checkedUseAsFilter ? 1 : inputs?.cat_id,
      fieldLength: '255',
      defaultValue: 'null',
      required: true,
      helpText: 'helpText',
      useAsFilter: checkedUseAsFilter,
      active: true,
    };
    if (customFieldOptions === 'sub-categories') {
      postObjData.subCategoryId = inputs.subCatId;
    }

    if (customFieldOptions === 'categories') {
      postObjData.categoryId = inputs.catId;
    }
    if (options.length > 0) {
      postObjData.options = options;
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postObjData),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/category/customeField`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        // setMessage({ error: false, msg: 'Added Successfully!' });
        toast.success('CustomField Added Successfully!');

        handleClose();
        fetchVideo();
      } else {
        // setMessage({ error: true, msg: 'error occured' });
        toast.error('Error occured');
      }
    } catch (error) {
      console.log(error);
      // setMessage({ error: true, msg: 'error occured' });
      toast.error('Error occured');
    }
  };

  const handleChangeUseAsFilter = (event) => {
    setCheckedUseAsFilter(event.target.checked);
  };

  useEffect(() => {
    fetchCoun();
    fetchSubs();
    fetchSubCategory();
    // fetchTrans();
  }, []);

  useEffect(() => {
    if (customFieldOptions === 'sub-categories') {
      setValidationObject((prev) => ({
        fieldName: yup.string('Enter Field Name').required('Field Name is required'),
        fieldType: yup.string('Enter Field Type').required('Field Type is required'),
        catId: yup.string('Select Category').required('Category is required'),
        subCatId: yup.string('Select Sub-Category').required('Sub-Category is required'),
      }));
    }
    if (customFieldOptions === 'categories') {
      setValidationObject((prev) => ({
        fieldName: yup.string('Enter Field Name').required('Field Name is required'),
        fieldType: yup.string('Enter Field Type').required('Field Type is required'),
        catId: yup.string('Select Category').required('Category is required'),
      }));
    }
    if (customFieldOptions === 'global') {
      setValidationObject((prev) => ({
        fieldName: yup.string('Enter Field Name').required('Field Name is required'),
        fieldType: yup.string('Enter Field Type').required('Field Type is required'),
      }));
    }

    formik.resetForm();
  }, [customFieldOptions]);

  return (
    <div>
      {message?.msg && (
        <Alert severity={message?.error ? 'error' : 'success'} dismissible={'true'} onClose={() => setMessage('')}>
          {message?.msg}
        </Alert>
      )}
      <Button variant="outlined" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Add New Field
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Custom Field Details</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </DialogContentText> */}
          <Box>
            <Box p={3}>
              <Box width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <h3>Add Custom Field By</h3>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ToggleButtonGroup
                      color="primary"
                      value={customFieldOptions}
                      exclusive
                      onChange={handleToggleButtonChange}
                      aria-label="Platform"
                    >
                      <ToggleButton value="global">Global</ToggleButton>
                      <ToggleButton value="categories">Categories</ToggleButton>
                      <ToggleButton value="sub-categories">Sub-Categories</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>

                  {customFieldOptions !== 'global' && (
                    <Grid item xs={12} md={12}>
                      <TextField
                        required
                        select
                        variant="outlined"
                        color="info"
                        fullWidth
                        label="Category"
                        name="catId"
                        value={formik.values.catId}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.catId && Boolean(formik.errors.catId)}
                        helperText={formik.touched.catId && formik.errors.catId}
                      >
                        {subs?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                  {customFieldOptions === 'sub-categories' && (
                    <Grid item xs={12} md={12}>
                      <TextField
                        required
                        select
                        variant="outlined"
                        color="info"
                        fullWidth
                        label="Sub-Category"
                        name="subCatId"
                        value={formik.values.subCatId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.subCatId && Boolean(formik.errors.subCatId)}
                        helperText={formik.touched.subCatId && formik.errors.subCatId}
                        // value={inputs.subcat_id || ''}
                        // onChange={handleChange}
                      >
                        {fiteredSubCategory?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}

                  <Grid item xs={12} md={12}>
                    <TextField
                      // autoFocus
                      required
                      margin="dense"
                      id="fieldName"
                      label="Field Name"
                      type="text"
                      name="fieldName"
                      value={formik.values.fieldName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.fieldName && Boolean(formik.errors.fieldName)}
                      helperText={formik.touched.fieldName && formik.errors.fieldName}
                      variant="outlined"
                      color="info"
                      fullWidth
                      // value={inputs.url || ''}
                      // onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      required
                      select
                      id="fieldType"
                      variant="outlined"
                      color="info"
                      fullWidth
                      label="Type of Field"
                      name="fieldType"
                      value={formik.values.fieldType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.fieldType && Boolean(formik.errors.fieldType)}
                      helperText={formik.touched.fieldType && formik.errors.fieldType}
                      // value={inputs.type_of_field || ''}
                      // onChange={handleChange}
                    >
                      {coun.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* *************{options}*********** */}
                  {showOptionField && (
                    <>
                      <Grid item xs={12} md={12}>
                        <TextField
                          // autoFocus
                          // margin="dense"
                          id="optionsTxt"
                          label="Options"
                          type="text"
                          variant="outlined"
                          color="info"
                          fullWidth
                          name="optionsTxt"
                          value={optionsTxt}
                          onChange={handleAddOptionTxt}
                        />
                      </Grid>
                      <Grid item xs={5} md={5}>
                        <Button onClick={handleAddOption} variant="outlined">
                          Add Options
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Stack direction="row" spacing={1}>
                          {options.map((item, index) => (
                            <Chip
                              label={item}
                              variant="filled"
                              color="info"
                              fullWidth
                              onDelete={() => handleDeleteOptions(item)}
                              key={item}
                            />
                          ))}
                        </Stack>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} md={12}>
                    <Divider sx={{ my: 2 }} />
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox // sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                            checked={checkedUseAsFilter}
                            onChange={handleChangeUseAsFilter}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label="Use As Filter"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={formik.handleSubmit} variant="outlined">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddField;
