import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import {
  MenuItem,
  Box,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Divider,
  Alert,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Chip,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Iconify from '../../components/iconify';
import baseUrl from '../../utils/baseUrl';
import toast from 'react-hot-toast';

const UpdatedCustomField = (props) => {
  const [open, setOpen] = useState(false);
  const [subs, setSubs] = useState([]);
  const [coun, setCoun] = useState([]);
  const [inputs, setInputs] = useState({
    cat_id: props.cat_id,
    url: props.selectedFieldItem[1],
    type_of_field: props.selectedFieldItem[2],
  });
  const [addMoreInput, setAddMoreInput] = useState([{}]);
  const [checkedUseAsFilter, setCheckedUseAsFilter] = React.useState(false);
  const [message, setMessage] = useState({ error: false, msg: '' });
  const [optionsTxt, setOptionsTxt] = useState('');
  const [options, setOptions] = useState([]);
  const [showOptionField, setShowOptionField] = useState(false);
  const [selectedFieldItemNow, setSelectedFieldItem] = useState({});

  const fetchCoun = async () => {
    const arr = [
      { id: 1, name: 'text' },
      { id: 2, name: 'file' },
      { id: 3, name: 'url' },
      { id: 4, name: 'number' },
      { id: 5, name: 'videoUrl' },
      // { id: 6, name: 'textarea' },
      // { id: 7, name: 'checkboxMulti' },
      // { id: 8, name: 'selectBox' },
      { id: 9, name: 'radio' },
      { id: 10, name: 'date' },
      { id: 11, name: 'dateTime' },
      { id: 12, name: 'dateRange' },
    ];
    setCoun(arr);

    setSelectedFieldItem(props.selectedFieldItem);
    // setInputs({ cat_id: props.cat_id, url: props.selectedFieldItem[1], type_of_field: props.selectedFieldItem[2] });
    setCheckedUseAsFilter(props.selectedFieldItem[4] === 'Yes' ? true : false);
    if (['selectBox', 'checkboxMulti', 'radio'].includes(props.selectedFieldItem[2])) {
      setOptions([...props.options]);
      setShowOptionField(true);
    } else {
      setOptions([]);
      setShowOptionField(false);
    }
  };

  useEffect(() => {
    // { fetchVideo,selectedFieldItem }
    fetchCoun();

    // fetchTrans();
  }, []);

  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;

    await setInputs((values) => ({ ...values, [name]: value }));
    if (['selectBox', 'checkboxMulti', 'radio'].includes(inputs.type_of_field)) {
      setOptions([]);
      setShowOptionField(true);
    } else {
      setOptions([]);
      setShowOptionField(false);
    }
  };

  const handleAddOption = () => {
    if (optionsTxt) {
      console.log('event------------->', optionsTxt, options);
      setOptions([...options, optionsTxt]);
      setOptionsTxt('');
    }
  };
  const handleAddOptionTxt = (event) => {
    console.log('event------------->', event.target.value);
    setOptionsTxt(event.target.value);
  };
  const handleDeleteOptions = (event) => {
    console.log('event------------->', event);
    const optionsList = options.filter((name) => name !== event);
    setOptions(optionsList);
  };

  const handleClickOpen = () => {
    setInputs({ cat_id: props.cat_id, url: props.selectedFieldItem[1], type_of_field: props.selectedFieldItem[2] });
    setOpen(true);
  };

  const handleClose = () => {
    setInputs({ cat_id: '', url: '', type_of_field: '' });
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    setAddMoreInput(['']);

    const postObjData = {
      name: inputs?.url,
      categoryId: checkedUseAsFilter ? 1 : inputs?.cat_id,
      type: inputs?.type_of_field,
      fieldLength: '255',
      defaultValue: 'null',
      required: true,
      helpText: 'helpText',
      useAsFilter: checkedUseAsFilter,
      active: true,
    };

    if (options.length > 0) {
      postObjData.options = options;
    }
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postObjData),
    };
    try {
      const data = await fetch(`${baseUrl}/api/v1/category/customefield/${props.selectedFieldItem[0]}`, requestOptions);
      const responseData = await data.json();
      if (responseData?.errors?.length === 0) {
        toast.success('CustomField Updated Successfully!');
        handleClose();
        props.fetchVideo();
      } else {
        setMessage({ error: true, msg: 'error occured' });
      }
    } catch (error) {
      console.log(error);
      setMessage({ error: true, msg: 'error occured' });
    }
  };

  const handleChangeUseAsFilter = (event) => {
    setCheckedUseAsFilter(event.target.checked);
  };
  return (
    <div>
      {message?.msg && (
        <Alert severity={message?.error ? 'error' : 'success'} dismissible={'true'} onClose={() => setMessage('')}>
          {message?.msg}
        </Alert>
      )}
      <Button variant="outlined" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Update
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update custom field details</DialogTitle>
        <DialogContent>
          <Box>
            <Box p={3}>
              <Box width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={1}>
                  {!checkedUseAsFilter && (
                    <Grid item xs={12} md={12}>
                      <TextField
                        select
                        label="Category"
                        name="cat_id"
                        variant="filled"
                        color="info"
                        fullWidth
                        value={inputs.cat_id || ''}
                        onChange={handleChange}
                        disabled
                      >
                        {props?.category?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                  <Grid item xs={12} md={12}>
                    <TextField
                      // autoFocus
                      margin="dense"
                      id="url"
                      label="Field Name"
                      type="text"
                      name="url"
                      variant="filled"
                      color="info"
                      fullWidth
                      value={inputs.url || ''}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      select
                      label="Type of Field"
                      name="type_of_field"
                      variant="filled"
                      color="info"
                      fullWidth
                      value={inputs.type_of_field || ''}
                      onChange={handleChange}
                      disabled
                    >
                      {coun.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* *************{options}*********** */}
                  {showOptionField && (
                    <>
                      <Grid item xs={12} md={12}>
                        <TextField
                          variant="filled"
                          color="info"
                          fullWidth
                          id="optionsTxt"
                          label="Field Name"
                          type="text"
                          name="optionsTxt"
                          value={optionsTxt}
                          onChange={handleAddOptionTxt}
                        />
                      </Grid>
                      <Grid item xs={5} md={5}>
                        <Button onClick={handleAddOption} variant="outlined">
                          Add Options
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Stack direction="row" spacing={1}>
                          {options.map((item, index) => (
                            <Chip
                              label={item}
                              variant="outlined"
                              onDelete={() => handleDeleteOptions(item)}
                              key={item}
                            />
                          ))}
                        </Stack>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} md={6}>
                    <Divider sx={{ my: 2 }} />
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox // sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                            checked={checkedUseAsFilter}
                            onChange={handleChangeUseAsFilter}
                            inputProps={{ 'aria-label': 'controlled' }}
                            disabled
                          />
                        }
                        label="Use As Filter"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="outlined">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default UpdatedCustomField;
