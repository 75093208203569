/* eslint-disable arrow-body-style */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { Stack, Container, Typography, Box, CircularProgress } from '@mui/material';
import DataGrid from '../components/Datagrid/Datagrid';
import baseUrl from '../utils/baseUrl';
import PositionedSnackbar from 'src/components/Snackbar/Snackbar';
import AddDiscussion from 'src/modules/Discussion/AddDiscussion';
import Component from 'src/modules/Discussion/UpdateDiscussion';

export default function Discussions() {
  const [open, setOpen] = useState(false);
  const [sucMsg, setSucMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [reload, setReload] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchVideo();
  }, [reload]);

  const fetchVideo = async () => {
    try {
      setLoading(true);
      const stringfyData = await fetch(`${baseUrl}/api/v1/disscussion-post`);
      const arr = await stringfyData.json();

      setAllFields(
        arr?.data?.dissQuestionData.map((field, key) => {
          return {
            'Serial No': key + 1,
            Code: field.id,
            Name: field.post_title,
            'Category Name': field.listing_category.name,

            'Total Post Views': field.totalPostViews,
            totalPostLikes: field.totalPostLikes,
            Location: field.location,
            description: field.content,
            'Created By': field.User.user_name,
            'Date Created': field.createdAt?.substring(0, 10),
            Status: field.status,
            tags: field.tags_on_post,
            category: field.categoryId,
            content: field.content,
          };
        })
      );
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onClickEvent = () => {};
  const snackBarHandler = (data) => {
    console.log(data);
    setOpen(data.open);
    setErrMsg(data.errMsg);
    setSucMsg(data.sucMsg);
    // setReload(data.reload);
    data.reload && fetchVideo();
  };
  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <>
      <Container>
        {/* <PositionedSnackbar
          open={open}
          message_err={errMsg}
          message_suc={sucMsg}
          handleClose={handleClose}
        /> */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Discussions
          </Typography>
          <AddDiscussion onSnackBar={snackBarHandler} />
        </Stack>
        {allFields?.length > 0 ? (
          <DataGrid
            data={allFields}
            onClickEvent={onClickEvent}
            checkBox
            pagingTool
            hideTool
            isFilter
            empGrid
            // wrap
            name="/api/v1/disscussion-post/"
            openSnackBar={snackBarHandler}
            renderComponent={Component}
          />
        ) : loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <CircularProgress /> &nbsp;Loading....
          </Box>
        ) : (
          <h1 className="text-base text-center font-semibold">Data Not Found!!</h1>
        )}
      </Container>
    </>
  );
}
